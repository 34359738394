import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import i18n from "../../translations/i18n";
import { setShowPhase, PHASE_FRONT_VIEW_CAMERA, PHASE_BASIC, PHASE_START } from "../../../actions/recommendationAction";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 786px) {
    min-height: 470px;
  }
`;

const SubContainer = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  padding-top: 3%;
  @media (max-width: 600px) {
    font-size: 16px;
    padding-top: 5%;
  }
`;

const Img = styled.img`
  padding: 5% 0px 0px;
  width: 46%;
  @media (max-width: 600px) {
    width: 91%;
    padding: 5%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

const NextButton = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 1em;
  justify-content: center;
  .MuiButton-containedPrimary {
    margin: 0px 30px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ViewInstructions = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const gender = useSelector((state: any) => state.recommendationReducer.gender) || url.searchParams.get("humanGender");
  const backPhase = useSelector((state: any) => state.recommendationReducer.backPhase);

  const locale = i18n.language === 'en' ? 'en' : i18n.language === 'fi' ? 'fi' : 'sw';

  const instruction_front_src =
    require(`../../translations/languages/${locale}/instructions/phase_front_view_instructions_${gender}.png`);
  const instruction_side_src =
    require(`../../translations/languages/${locale}/instructions/phase_side_view_instructions_${gender}.png`);


  return (
    <Container>
      <SubContainer>{children}</SubContainer>
      <ImageWrapper>
        <Img src={instruction_front_src} alt="Error_image" />
        <Img src={instruction_side_src} alt="Error_image" />
      </ImageWrapper>
      <NextButton>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={() => backPhase === (PHASE_START || PHASE_BASIC) ? dispatch(setShowPhase(backPhase)) : navigate(backPhase)}
        >
          {t("back")}
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className="next-button"
          onClick={() => dispatch(setShowPhase(PHASE_FRONT_VIEW_CAMERA))}
        >
          {t("next")}
        </Button>
      </NextButton>

    </Container>
  );
};
export default ViewInstructions;
