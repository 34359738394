import React, { useContext, useState } from 'react'
import { Field } from 'redux-form'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

import ReduxPrimaryInput from '../atoms/ReduxPrimaryInput'
import { getUserInfoSuccess } from '../../actions/UserAction'
import { FirebaseContext } from '../../providers/FirebaseProviders'
import i18n from '../translations/i18n'
import Card from '../../common/Card'
import ReduxSelectBox from '../atoms/ReduxSelectBox'
import ReduxCheckBox from '../atoms/ReduxCheckBox'
import { capitalize } from '../../common/helper'
import CustomButton from '../../common/CustomButton'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const FormSection = styled.div`
    margin: 20px 0px 100px 0px;
    width: 350px;
    height: 270px;
    display: flex;
    flex-direction: column;
    .MuiNativeSelect-select:not([multiple]) option {
        background: ${({ theme }) => theme.colors.sizeChartTable.background};
    }
    @media (max-width: 600px) {
        width: 100%;
    }
    
`;

const OneLine = styled.div`
    display: flex;
    justify-content: center;
    .MuiFormControlLabel-label.Mui-disabled {
        color: ${({theme}) => theme.colors.disableButtonColor};
    }
    .MuiCheckbox-colorPrimary.Mui-disabled {
        color: ${({theme}) => theme.colors.disableButtonColor};
    }
    .MuiFormControlLabel-root {
        width: 95%;
        text-align: center;
        display: initial;
        margin: 0px;
    }
    .MuiTypography-body1 {
        line-height: 0px;
    }
`

const MultipleInput = styled.div`
    width: 100%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 600px) {
        min-width: 100px;
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .MuiButton-containedPrimary {
        margin: 0px 10px;
    }
`;

const DisplayName = styled.h2`
    margin-top: 0px;
    font-weight: 700;
`;

const ProfileDetail = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 120px;
`;

const Data = styled.h2`
    font-size: 20px;
    margin: 0px;
    font-weight: 500;
`;

const Label = styled.h2`
    font-size: 12px;
    font-weight: 400;
    color: #B9B9B9;
    white-space: nowrap;
`;
interface ProfileInterface {
    handleSubmit: any,
    isGender?: string | undefined
    isHeight?: string | undefined
    isWeight?: string | undefined
    reset?: any
    FORM_NAME: string
    displayNameValue?: string | undefined,
    invalid: boolean,
    loginUser: any,
    fitPreference: string,
    useTapeMeasurement: boolean
}
const minHeight = (value: number) => value && value < 120 ? i18n.t(`minHeight`, { height: 120 }) : undefined;
const maxHeight = (value: number) => value && value > 250 ? i18n.t(`maxHeight`, { height: 250 }) : undefined;
const required = (value: number) => !value ? i18n.t(`required`) : undefined;

const EditProfile = (props: ProfileInterface) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const authContext = useContext(FirebaseContext)
    const { user } = authContext
    const [editProfile, setEditProfile] = useState<any>(false)
    const showServices = useSelector((state: any) => state.userInfo.measurements);
    const [isLoading, setIsLoading] = useState(false);

    const { handleSubmit, invalid, isGender, isHeight, isWeight, displayNameValue, reset, loginUser, fitPreference, FORM_NAME, useTapeMeasurement } = props;

    const handleProfileField = () => {
        setEditProfile(!editProfile)
    }

    const ButtonComponent = (props: any) => {
        return (
            <Button
                color='primary'
                variant='contained'
                className="edit-profile"
                onClick={handleProfileField}
            >{t('editProfile')}</Button>
        )
    }

    const handleProfile = async (value: any) => {
        if (authContext.user) {
            const token = await authContext.user.getIdToken(true);
            setIsLoading(true);
            await fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}me`, {
                method: 'PUT',
                headers: {
                    Accept: '*/*',
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    height: value.height,
                    gender: value.gender,
                    weight: value.weight,
                    email: authContext.user.email,
                    displayName: value.displayName,
                    fitPreference: value.fitPreference,
                    useTapeMeasurement: value.useTapeMeasurement
                }),
            }).then(async res => {
                const body = await res.json();
                setIsLoading(false)
                if (!!body) {
                    await dispatch(getUserInfoSuccess(body))
                    handleProfileField()
                }
            })
        }
    }

    const gender = [
        { value: 'male', label: t('male') },
        { value: 'female', label: t('female') },
    ]

    const fitPreferenceData = [
        { value: 'tight', label: t('tight') },
        { value: 'regular', label: t('regular') },
        { value: 'loose', label: t('loose') },
    ]

    return (
        <Card button={!editProfile && <ButtonComponent />} heading={t(`sizey_profile`)} logo={`https://eu.ui-avatars.com/api/?background=003777&color=fff&rounded=true&name=${loginUser?.displayName ? loginUser?.displayName : user?.email}`}>
            {editProfile && <form onSubmit={handleSubmit(handleProfile)}>
                <FormSection>
                    <MultipleInput>
                        <Field
                            type='text'
                            name='displayName'
                            className='input-text'
                            helperText={t(`display_name`)}
                            width="100px"
                            component={ReduxPrimaryInput}
                        />
                        <Field
                            name='gender'
                            component={ReduxSelectBox}
                            values={gender}
                            defaultValue={isGender || 'male'}
                            helperText={t(`gender`)}
                        />

                    </MultipleInput>
                    <MultipleInput>
                        <Field
                            type='number'
                            name='height'
                            className='input-text'
                            helperText={t(`height_in_cm`)}
                            component={ReduxPrimaryInput}
                            width="100px"
                            validate={[required, minHeight, maxHeight]}
                        />
                        <Field
                            type='number'
                            name='weight'
                            className='input-text'
                            helperText={t(`weight_in_Kg`)}
                            component={ReduxPrimaryInput}
                            width="100px"
                        />
                    </MultipleInput>
                    <MultipleInput>
                        <Field
                            name='fitPreference'
                            component={ReduxSelectBox}
                            values={fitPreferenceData}
                            defaultValue={fitPreference || 'regular'}
                            helperText={t(`fitPreference`)}
                        />
                    </MultipleInput>
                    <OneLine>
                        <Field
                            name='useTapeMeasurement'
                            className='input-text'
                            label={t('manualMeasurement')}
                            component={ReduxCheckBox}
                            defaultValue={useTapeMeasurement || false}
                            width="100px"
                        />
                    </OneLine>
                </FormSection>
                <ButtonContainer>
                    <CustomButton
                        type='submit'
                        color='primary'
                        variant='contained'
                        disabled={!isHeight || !displayNameValue || invalid || !isWeight}
                        isloading={isLoading}
                        name={t(`save`)}
                    />
                    <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                            dispatch(reset(FORM_NAME))
                            handleProfileField()
                        }}
                    >
                        {t(`cancel`)}
                    </Button>
                </ButtonContainer>
            </form>}
            {!editProfile && <>
                <FormSection>
                    <OneLine>
                        <DisplayName>{displayNameValue}</DisplayName>
                    </OneLine>
                    <MultipleInput>
                        <ProfileDetail>
                            <Data>{showServices.length}</Data>
                            <Label>{t(`totalMeasurement`)}</Label>
                        </ProfileDetail>
                        <ProfileDetail>
                            <Data>{capitalize(t(`${isGender || 'male'}`))}</Data>
                            <Label>{t(`gender`)}</Label>
                        </ProfileDetail>
                    </MultipleInput>
                    <MultipleInput>
                        <ProfileDetail>
                            <Data>{isHeight || 0}</Data>
                            <Label>{t(`height_in_cm`)}</Label>
                        </ProfileDetail>
                        <ProfileDetail>
                            <Data>{isWeight || 0}</Data>
                            <Label>{t(`weight_in_Kg`)}</Label>
                        </ProfileDetail>
                    </MultipleInput>
                    <MultipleInput>
                        <ProfileDetail>
                            <Data>{t(fitPreference) || '-'}</Data>
                            <Label>{t(`fitPreference`)}</Label>
                        </ProfileDetail>
                    </MultipleInput>
                    <OneLine>
                        <FormControlLabel
                            disabled
                            control={<Checkbox checked={useTapeMeasurement || false} color="primary" />}
                            label={t('manualMeasurement')}
                            labelPlacement="end"
                        />
                    </OneLine>
                </FormSection>
            </>}
        </Card>
    )
}

export default EditProfile
