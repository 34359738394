import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import userInformation from './user-info'
import recommendationReducer from './recommendationReducer'

export const rootReducer = combineReducers({
    form: formReducer,
    recommendationReducer: recommendationReducer,
    userInfo: userInformation
})

export type RootState = ReturnType<typeof rootReducer>
