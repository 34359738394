import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginRight: '5px'
  },
  signInButton: {
    backgroundColor: '#D0D3E3',
    borderRadius: '20px',
    color: '#003777',
    border: '2px solid #8a8ca5',
    textTransform: 'capitalize',
    dispaly: 'inline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#9ea0b3',
    },
    width: '135%',
  },
  signUpButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    color: '#003777',
    border: '2px solid #8a8ca5',
    textTransform: 'capitalize',
    dispaly: 'inline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E3F2FD',
    },
    width: '135%',
  },
}));

export default function LoginButton({t}: any){
  const location = useLocation();
  const search = (location.search).replace('?', '');
  const classes = useStyles();
  return (
    <Grid container className={classes.loginContainer} spacing={5}>
      <Grid item xs={6}>
        <Button
          variant="contained"
          className={classes.signInButton}
          onClick={() => window.location.href = `${process.env.REACT_APP_LOGIN_URL}&${search}&path=${location.pathname}`}
        >
          {t('Sign In')}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          className={classes.signUpButton}
          onClick={() => window.location.href = `${process.env.REACT_APP_REGISTER_URL}&${search}&path=${location.pathname}`}
        >
          {t('Sign Up')}
        </Button>
      </Grid>
    </Grid>
  );
}