import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Form from "../Form";
import { setHumanGenderAndHeight } from "../../../actions/recommendationAction";

const Container = styled.div`
  width: 100%;
  padding-top: 3%;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    padding-top: 5%;
  }
`;

const BasicInformation = () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Form
        onSubmit={(values: any) => {
          dispatch(setHumanGenderAndHeight({ values }))
        }}
      />
    </Container>
  );
};

export default BasicInformation;
