import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Hourglass from "../../../assets/images/hourglass.gif";

const Container = styled.div`
  min-height: 78vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const HourglassImage = styled.img`
  position: relative;
  text-align: center;
  width: 64px;
  margin: 0 auto;
`;

const MakingSilhouetteText = styled.div`
  margin: 0;
  color: ${({ theme}) => theme.colors.text};
  font-size: 22px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ProcessSilhouette = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <HourglassImage src={Hourglass} alt="" />
      <MakingSilhouetteText>
        {t("pleaseWaitMakingSilhouette")}
      </MakingSilhouetteText>
    </Container>
  );
};

export default ProcessSilhouette;
