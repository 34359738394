import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useContext, useEffect } from 'react'

import { getShopsUrl, getUserInfo, getUserMeasurements, userMeasurement } from '../actions/UserAction'
import { FirebaseContext } from '../providers/FirebaseProviders'

const AuthRedirect = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const authContext = useContext(FirebaseContext);
    const location = useLocation()
    useEffect(() => {
        (async () => {
            const queryParams = new URLSearchParams(location.search);
            if (authContext.user) {
                const token = await authContext?.user?.getIdToken(true)
                dispatch(getUserMeasurements(token, navigate));
                dispatch(getUserInfo(token))
                dispatch(getShopsUrl(token))
                if (location.search.includes('saved')) {
                    const measurement_id = queryParams.get('measurementId');
                    if (measurement_id) {
                        await dispatch(userMeasurement({ measurement_id, tape: true }, token));
                    }
                    navigate({
                        search: queryParams.toString().replace('&saved=', ''),
                    })
                }
            }
            const UPC = queryParams.get('UPC') || queryParams.get('upc');
            const productId = queryParams.get('productId');
            if (UPC || productId) {
                // await dispatch(getSizeChart(UPC, productId));
            }
        })()
        // eslint-disable-next-line 
    }, [authContext])
}

export default AuthRedirect
