import Female_Silhouette from '../assets/images/female_silhouette.png'
import Female_Silhouette_Dark from '../assets/images/female_silhouette_dark.png'
import Male_Silhouette from '../assets/images/male_silhouette.png'
import Male_Silhouette_Dark from '../assets/images/male_silhouette_dark.png'
import ProfileLight from '../assets/images/profile-light.svg'
import ProfileDark from '../assets/images/profile-dark.svg'
import MoonDark from '../assets/images/moon.svg'
import SunDark from '../assets/images/sun.svg'
import MoonLight from '../assets/images/white_moon.svg'
import SunLight from '../assets/images/white_sun.svg'
import TapeLogo from '../assets/images/tape.svg'
import TapeLogoWhite from '../assets/images/tape-white.svg'
import MenuMoonIcon from '../assets/images/menu_moon.svg'
import LogOutSvg from '../assets/images/icon_2.svg'
import MeasurementSvg from '../assets/images/icon_3.svg'
import ProfileSvg from '../assets/images/icon_4.svg'
import ShopSvg from '../assets/images/icon_5.svg'
import LogOutDarkSvg from '../assets/images/white_logout.svg'
import MeasurementDarkSvg from '../assets/images/measurement_dark.svg'
import ProfileDarkSvg from '../assets/images/profile_dark.svg'
import ShopDarkSvg from '../assets/images/shop_dark.svg'
import LanguageIcon from '../assets/images/icon_6.svg'
import LanguageDark from '../assets/images/language_dark.svg'
import LightGlobe from '../assets/images/globe_black.svg'
import BlackGlobe from '../assets/images/globe_white.svg'

export const THEME = {
    "light": {
        "name": "Light",
        "colors": {
            "body": "#FFFFFF",
            "text": "#003777",
            "color": "#000000",
            "borderColor": 'rgba(0, 0, 0, 0.23)',
            "disableButtonColor": 'rgba(0, 0, 0, 0.24)',
            "disableButtonBg": 'rgba(0, 0, 0, 0.12)',
            "link": {
                "text": "#003777",
                "opacity": 1
            },
            "sizeChartTable": {
                "background": '#F4F7FC'
            },
            "card": "#FFFFFF",
            "toggleBg": "rgba(0, 0, 0, 0.12))",
            "toggleBorder": "rgba(0, 0, 0, 0.12)"
        },
        "female_silhouette": Female_Silhouette,
        "male_silhouette": Male_Silhouette,
        "profileUrl": ProfileLight,
        "moonIcon": MoonDark,
        "sunIcon": SunDark,
        "logoutIcon": LogOutSvg,
        "tapeLogo": TapeLogo,
        "menuMoonIcon": MenuMoonIcon,
        "measurementIcon": MeasurementSvg,
        "profileIcon": ProfileSvg,
        "shopIcon": ShopSvg,
        "languageIcon": LanguageIcon,
        "globe": LightGlobe
    },
    "dark": {
        "name": "Dark",
        "colors": {
            "body": "#121212",
            "text": "#FFFFFF",
            "color": "#FFFFFF",
            "borderColor": '#9d9898',
            "disableButtonColor": 'rgb(247 242 242 / 24%)',
            "disableButtonBg": 'rgb(247 242 242 / 8%)',
            "link": {
                "text": "#FFFFFF",
                "opacity": 0.8
            },
            "sizeChartTable": {
                "background": '#212121'
            },
            "card": "#212121",
            "toggleBg": "rgb(227 220 220 / 12%)",
            "toggleBorder": "rgb(243 236 236 / 12%)"
        },
        "female_silhouette": Female_Silhouette_Dark,
        "male_silhouette": Male_Silhouette_Dark,
        "profileUrl": ProfileDark,
        "moonIcon": MoonLight,
        "sunIcon": SunLight,
        "logoutIcon": LogOutDarkSvg,
        "tapeLogo": TapeLogoWhite,
        "menuMoonIcon": SunLight,
        "measurementIcon": MeasurementDarkSvg,
        "profileIcon": ProfileDarkSvg,
        "shopIcon": ShopDarkSvg,
        "languageIcon": LanguageDark,
        "globe": BlackGlobe
    }
}