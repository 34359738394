import { useState } from 'react';
import { THEME } from './constant';

export const useTheme = () => {
  const themes: any = THEME;
  const [theme, setTheme] = useState(themes.light);

  const setMode = (mode: any) => {
    const themeChange = themes[mode]
    setTheme(themeChange);
  };


  return { theme, setMode };
};