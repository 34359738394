import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoute';
import FirebaseProvider from './providers/FirebaseProviders'


const App = () => {

  return (
    <FirebaseProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </FirebaseProvider>
  );
}

export default (App)