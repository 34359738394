import * as React from 'react'
import { createContext, useEffect, useState } from 'react'
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';

interface FirebaseContextState {
    user?: User | null;
    auth?: ReturnType<typeof getAuth>;
}

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_HOSTING_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_HOSTING_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_HOSTING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_HOSTING_APP_ID
  };

export const firebaseConfig = initializeApp(config)

export const FirebaseContext = createContext<FirebaseContextState>({});

const FirebaseProvider = (props:any) => {

    const [firebaseContextState, setFirebaseContextState] = useState<FirebaseContextState>({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const auth = getAuth();
        setFirebaseContextState(currentState => ({ ...currentState, auth }))
        return onAuthStateChanged(auth, (user: User | null) => {
            setFirebaseContextState(current => ({ ...current, user: user }))
            setLoading(false)
        })
    }, [])

    return (
        <FirebaseContext.Provider value={firebaseContextState}>
            {!loading && props.children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider
