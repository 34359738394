import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
    height: 1200px;
    overflow: auto;
`;

const List = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    @media (max-width: 600px) {
        padding: 2px;
    }
`;

const IconSection = styled.div`
    width: 20px;
    height: 20px;
    background: gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
`;

const SubContainer = styled.div`
    display: flex;
    align-items: center;
    width: 150px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    };
    input[type=number] {
        -moz-appearance: textfield;
      }
    @media (max-width: 600px) {
        width: 120px;
    }
`;

const InputBox = styled.input`
    width: 40%;
    height: 24px;
    border-radius: 5px;
    border: 1px solid #8c8c8a;
    text-align: center;
    font-size: 16px;
`;

const uiMeasurements = (gender: string) => {
    return [
        { label: "neckCircumference", key: 'neck.circumference', gender: ['f', 'm'] },
        { label: "shoulderWidth", key: 'shoulder.width', gender: ['f', 'm'] },
        { label: "chestCircumference", key: 'chest.circumference', gender: ['f', 'm'] },
        { label: "underBust", key: 'underBust.circumference', gender: ['f'] },
        { label: "maxWaistCircumference", key: 'waist.circumference', gender: ['f', 'm'] },
        { label: "pelvisHipCircumference", key: 'hip.circumference', gender: ['f', 'm'] },
        { label: "thighCircumference", key: 'thigh.circumference', gender: ['f', 'm'] },
        { label: "calfCircumference", key: 'calf.circumference', gender: ['f', 'm'] },
        { label: "insideLeg", key: 'insideLeg.length', gender: ['f', 'm'] },
        { label: "armLength", key: 'arm.length', gender: ['f', 'm'] }
    ].filter(v => v.gender.includes((gender && gender[0]) || 'm'));
}

const CircumferenceList = (props: any) => {
    const { t } = useTranslation();
    const { changeUnitValue, units, userDetail } = props;
    const divStyles = {
        fontSize: 16,
        color: '#fff',
        '@media (maxWidth: 600px)': {
            fontSize: 14,
        },
    };

    return (
        <Container>
            {uiMeasurements(userDetail.gender).map((meas, index) =>
                <List key={index}>
                    <Typography style={{ width: '150px' }}>{`${t(meas.label)}`}</Typography>
                    <SubContainer>
                        <IconSection>
                            <RemoveIcon style={divStyles} onClick={() => changeUnitValue((units && (units[meas.key] / 10) - 1) || 0, meas.key)} />
                        </IconSection>
                        <InputBox type='number' value={(units && units[meas.key] / 10) || ''} placeholder='0' onChange={(e: any) => changeUnitValue(+e.target.value, meas.key)} />
                        <IconSection style={{ backgroundColor: '#003777' }}>
                            <AddIcon style={divStyles} onClick={() => changeUnitValue((units && (units[meas.key] / 10) + 1) || 1, meas.key)} />
                        </IconSection>
                    </SubContainer>
                </List>
            )}
        </Container>
    )
}

export default CircumferenceList;