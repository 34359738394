import {
    GET_USER_INFO_ERROR, GET_USER_INFO_START, GET_USER_INFO_SUCCESS,
    GET_MEASUREMENTS_USER_SUCCESS,
    GET_3D_AVATAR_ERROR,
    GET_3D_AVATAR_START,
    GET_3D_AVATAR_SUCCESS,
    SHOP_URL,
} from '../actions/UserAction'

const initialState = {
    user: {},
    measurements: [],
    isLoading: false,
    poses: '',
    avatar: {},
    shopUrl: []
}

const userInformation = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_USER_INFO_START:
        case GET_USER_INFO_ERROR:
            return {
                ...state,
                ...action.payload
            }
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                user: action.payload,
                isLoading: action.payload.isLoading
            }
        case GET_MEASUREMENTS_USER_SUCCESS:
            return {
                ...state,
                measurements: action.payload.measurements,
                isLoading: action.payload.isLoading
            }
        case GET_3D_AVATAR_START:
        case GET_3D_AVATAR_SUCCESS:
        case GET_3D_AVATAR_ERROR: {
            return {
                ...state,
                ...action.payload
            }
        }
        case SHOP_URL: {
            return {
                ...state,
                shopUrl: action.payload.shops
            }
        }
        default:
            return {
                ...state
            }
    }
}

export default userInformation
