import React from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

import SelectGender from "../../common/SelectGender";
import { TextField } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const PageTitle = styled.label`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const FormBody = styled.form`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  align-items: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const InputFieldWrapper = styled.div`
  margin-top: 1em;
  width: 50%;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const NextButton = styled.div`
  width: 50%;
  text-align: center;
  padding-top: 1em;
  justify-content: center;
  .MuiButton-containedPrimary {
    margin: 0px 30px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const SelectOption = styled.div`
  width: 50%;
  margin-top: 5%;
  @media (max-width: 600px) {
    width: auto;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Error = styled.div`
  font-size: 16px;
  color: #008000;
`;

const Input = (props: any) => {
  const { form: { dirty, errors }, input, className, field, type, placeholder } = props;

  return (
    <div>
      <Container>
        <TextField
          id="outlined-full-width"
          label={`${placeholder} (cm)`}
          variant="outlined"
          {...field}
          fullWidth
          type={type}
          {...input}
        />
      </Container>
      {dirty && !!errors[className] && <Error>{errors[className]} </Error>}
    </div>
  );
};

const StyledRadioSelect = SelectGender;

const Form = (props: any) => {
  const { onSubmit } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    gender: Yup.string().oneOf(["m", "f"]).required("Required"),
    height: Yup.number()
      .test("height", t(`max_height`, { height: 250 }), value => (!!value && value < 250))
      .test("height", t(`min_height`, { height: 120 }), value => (!!value && value > 120))
      .required("Required"),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount={false}
      initialValues={{
        gender: "",
        height: "",
      }}
    >
      {({ handleSubmit, values }: any) => {
        return (
          <FormBody onSubmit={handleSubmit}>
            <PageTitle>{t("Personal Information")}</PageTitle>
            <SelectOption>
              <Field
                component={StyledRadioSelect}
                options={[
                  { label: "female", value: "female" },
                  { label: "male", value: "male" },
                ]}
                type="text"
                name="gender"
              />
            </SelectOption>

            <InputFieldWrapper>
              <Field
                component={Input}
                type="number"
                name="height"
                placeholder={t(`Height`)}
                className="height"
              />
            </InputFieldWrapper>

            <NextButton>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => navigate('/')}
              >
                {t("back")}
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={!(values.gender && values.height > 120 && values.height < 250)}
                onClick={() => onSubmit(values)}
              >
                {t("next")}
              </Button>
            </NextButton>
          </FormBody>
        );
      }}
    </Formik>
  );
};

export default Form;
