import React from "react";
import styled from "styled-components";

const Container: any = styled.div.withConfig({
  shouldForwardProp: (prop) => !['lastphase'].includes(prop)
})<{ bgcolor: string, lastphase?: boolean }>`
  display: flex;
  width: ${(props: any) => props.lastphase ? '100%': '35%'};
  height: 100%;
  border-radius: 50%;
  background-color: ${(props: any) => props.bgcolor};
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    width: ${(props: any) => props.lastphase ? '100%': '70%'};
    max-width: 40px;
    min-width: 40px;
    height: 40px;
  }
`;

const Image = styled.img`
  height: 50%;
  width: 50%;
`;

const PhaseIndicator = ({ bgcolor, src, lastphase }: { bgcolor: string, src: string, lastphase?: boolean }) =>
  <Container bgcolor={bgcolor} lastphase={lastphase}>
    <Image src={src} />
  </Container>

export default PhaseIndicator;
