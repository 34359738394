import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Selected from "../assets/images/selected.svg";
import UnSelected from "../assets/images/unselected.svg";
import MaleSelected from "../assets/images/male-selected.svg";
import FemaleSelected from "../assets/images/female-selected.svg";
import MaleUnSelected from "../assets/images/male-unselected.svg";
import FemaleUnSelected from "../assets/images/female-unselected.svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SubContainer = styled.div`
  display: flex;
  height: 200px;
  width: 100px;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.sizeChartTable.background};
  box-shadow: 0px 0px 8px #00000060;
  border: ${(props: any) =>
    props.selected ? "3px solid #7BA5BF" : "3px solid gray"};
  border-radius: 16px;
  justify-content: center;
  @media (max-width: 600px) {
    margin-top: 30px;
  }
`;

const Image = styled.img`
  position: relative;
  margin: 0;
  right: -47%;
  top: 10px;
`;

const ChoseImg = styled.img`
  display: flex;
  margin: 0;
`;

const GenderOption = styled.div`
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 10px;
`;

const SelectGender = (props: any) => {
  const { className, field, form, options } = props;
  const { t } = useTranslation();

  return (
    <Container className={className}>
      {options.length > 0 && options.map((o: any) => {
        const selected = field.value === o.value;
        return (
          <SubContainer
            key={o.value}
            onClick={() => {
              form.setFieldValue(field.name, o.value);
            }}
          >
            <Image
              height="32px"
              src={selected ? Selected : UnSelected}
              alt=""
            />
            {o.value === "female" && (
              <ChoseImg
                height="100px"
                src={selected ? FemaleSelected : FemaleUnSelected}
                alt="Female"
              />
            )}
            {o.value === "male" && (
              <ChoseImg
                height="100px"
                src={selected ? MaleSelected : MaleUnSelected}
                alt="Male"
              />
            )}
            <GenderOption>
              {o.value === "female" ? t("female") : t("male")}
            </GenderOption>
          </SubContainer>
        );
      })}
    </Container>
  );
};

export default SelectGender;
