import React from 'react';
import styled from "styled-components";
import { Button } from '@material-ui/core';

const Container = styled.div`
.dual-ring {
    display: flex;
  }
  .dual-ring:after {
    content: " ";
    display: block;
    width: 20px;
    height: 14px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: dual-ring 1.2s linear infinite;
  }
  @keyframes dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
`;

const CustomButton = ({ isloading = false, color, className, handleClick, name, ...rest }: any) => {
  // const ({ isloading = false, color, className, handleClick, name, ...rest }: any) = props;
  return (<Container>
    {isloading ? <Button variant="contained" disabled className="dual-ring">{name}</Button> :
      <Button
        color={color}
        onClick={handleClick}
        className={className}
        {...rest}
      >{name}</Button>}
  </Container>)
}

export default CustomButton;