import { FunctionComponent, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LogoImages from '../assets/images/sizey_logo_white.png'
import Moon from '../assets/images/moon.svg'
import Sun from '../assets/images/sun.svg'
import styled from 'styled-components'
import { firebaseConfig, FirebaseContext } from '../providers/FirebaseProviders';
import { getAuth } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { MY_MEASUREMENT, PHASE_BASIC, PHASE_FRONT_VIEW_INSTRUCTIONS, PHASE_FRONT_VIEW_REVIEW, PHASE_MEASURING, PHASE_PROCESS_FRONT_PICTURE, PHASE_PROCESS_SIDE_PICTURE, PHASE_SIDE_VIEW_CAMERA, PHASE_SIDE_VIEW_REVIEW, setClearBothPictures, setHomeCard, setMeasurements, setPicture, setRecommendations, setShowPhase, setSidePicture, setSizeChart, SET_FRONT_PICTURE, SIZEY_PROFILE, SIZEY_SHOP } from '../actions/recommendationAction';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../common/Colors';
import { Button, MenuItem } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ConfirmationPopup from '../common/ConfirmationPopup'
import LoginButton from '../common/LoginButton';

const { WHITE, BLUE, BLACK } = COLORS;

interface DropdownContentProps {
  open: boolean;
}

const MENU_ITEMS = [{
  label: 'sizey_profile',
  icon: 'profileIcon',
  phase: SIZEY_PROFILE
},
{
  label: 'my_measurements',
  icon: 'measurementIcon',
  phase: MY_MEASUREMENT
}, {
  label: 'my_sizey_shop',
  icon: 'shopIcon',
  phase: SIZEY_SHOP
}]



const Image: any = styled.img`
  height: ${(props: any) => props.height ? `${props.height}px` : '25px'};
  cursor: pointer;
  z-index: 99;
  width: ${(props: any) => props.width ? `${props.width}px` : '95px'};
`

const NavBarIcons = styled.div`
  display: flex;
  padding: 26px 30px;
  justify-content: space-between;
  .MuiButton-root {
    padding: 0px;
  }
  background-color: ${BLUE};
  @media(max-width: 768px) {
    padding: 20px 30px;
  }
  @media(max-width: 600px) {
    padding: 20px 15px;
  }
  .log-out{
    color: ${WHITE};
    cursor: pointer;
    z-index: 99;
    padding-right: 10px;
    font-weight: 500;
    font-size: 20px;
    @media(max-width: 600px){
      display: none;
    }
  }
  .menu-btn {
      color: ${WHITE};
      z-index: 999;
  }
`

const ChangeLanguage = styled.div`
  display: flex;
`
const ThemeChange = styled.div`
  background: ${WHITE};
  border-radius: 20px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
`;

const LanguageDropdown = styled.div`
  position: relative;
`;

const DropdownButton = styled.div`
  height: 25px;
  width: 40px;
  margin-left: 20px;
  background: ${WHITE};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${BLACK};
`;

const DropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  top: 30px;
  background-color: ${({ theme }) => theme.colors.card};
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Label = styled.p`
  margin: 0px;
  font-size: 16px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.text};
`;

const ListSection = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  & path {
    fill: 'red';
  }
`;

const SideDropdown = styled.div`
  position: relative;
  display: block;
  float: left;
  flex-direction: column;
  z-index: 1;
  margin-top: 5px;
`;

interface DropdownChildProps {
  $leftvalue: string;
}


const DropdownChild = styled.div<DropdownChildProps>`
  position: fixed;
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  top: 240px;
  left: ${({ $leftvalue }) => $leftvalue};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.card};
  box-shadow: 0px 10px 16px 2px rgba(0, 0, 0, 0.2);
`;

const DeskTopView = styled.div`
display: flex;
@media(max-width: 600px) {
  display: none;
}
`;

const MobileView = styled.div`
  display: none;
  @media(max-width: 600px) {
    display: block;
  }
`;

const leftValues = {
  en: 'calc(100% - 325px)',
  fi: 'calc(100% - 313px)',
  sw: 'calc(100% - 299px)'
};

const Navbar: FunctionComponent<any> = ({ theme, setMode }: any) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const { user } = useContext(FirebaseContext);
  const userInfo = useSelector((state: any) => state.userInfo.user);
  const shopUrl = useSelector((state: any) => state.userInfo.shopUrl);
  const currentProcess = useSelector(
    (state: any) => state.recommendationReducer.phase
  );
  const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
  const productId: any = url.searchParams.get('productId');
  const paramsMeasurementId = url.searchParams.get("measurementId");

  // const [language, setLanguage] = useState('en');
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isInnerDropdownOpen, setIsInnerDropdownOpen] = useState(false);
  type Language = 'en' | 'fi' | 'sw';
  const getBaseLanguage = (languageCode: string): Language => {
    const baseLang = languageCode.split('-')[0];
    return (['en', 'fi', 'sw'].includes(baseLang) ? baseLang : 'en') as Language;
  };
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(getBaseLanguage(i18n.language));

  const handlePopupClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (lang: Language) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
    setSelectedLanguage(lang);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsInnerDropdownOpen(false);
  };

  const handleLogout = () => {
    handleClose()
    const auth = getAuth(firebaseConfig);
    auth.signOut();
    dispatch(setSizeChart(undefined))
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}?path=${encodeURIComponent(`${window.location.origin}/${url.search}`)}`;
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const goToHome = () => {
    dispatch(setClearBothPictures());
    dispatch(setRecommendations(undefined))
    navigate(`/${url.search}`);
  };

  const handleDarkTheme = () => {
    if (theme.name === 'Light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }

  const handleOpen = (phase: any) => {
    setOpen(true);
    handleClose()
    dispatch(setHomeCard(phase));
  }

  const handleOnClick = () => {
    navigate(`/${url.search}`)
    handleClose()
    dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS))
    dispatch(setSidePicture(null))
    dispatch(setPicture(
      SET_FRONT_PICTURE,
      null
    ))
  }

  const handleChange = async (phase: any) => {
    navigate(`/${url.search}`);
    if(paramsMeasurementId && (UPC || productId)) {
      dispatch(setMeasurements(undefined));
      dispatch(setRecommendations(undefined))
    }
    handleClose()
    if (userInfo.gender) {
      dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS))
    } else {
      dispatch(setShowPhase(PHASE_BASIC))
    }
    dispatch(setHomeCard(phase));
    dispatch(setSidePicture(null))
    dispatch(setPicture(
      SET_FRONT_PICTURE,
      null
    ))
  }

  const MOBILE_MENU = [
    {
      label: `Language (${i18n.language.includes('en') ? 'EN' : i18n.language.includes('fi') ? 'FI' : 'SW'})`,
      icon: 'languageIcon',
      handleClick: () => {
        setIsInnerDropdownOpen(!isInnerDropdownOpen);
      },
      subMenu: [
        { label: 'English', languageCode: 'en' },
        { label: 'Finnish', languageCode: 'fi' },
        { label: 'Swedish', languageCode: 'sw' },
      ],
    },
    {
      label: theme.name === 'Light' ? t('dark_mode') : t('light_mode'),
      icon: 'menuMoonIcon',
      handleClick: () => {
        handleClose()
        handleDarkTheme()
      }
    }]

  const openConfirmationPopupFor = [
    PHASE_FRONT_VIEW_REVIEW,
    PHASE_SIDE_VIEW_CAMERA,
    PHASE_SIDE_VIEW_REVIEW,
    PHASE_MEASURING,
    PHASE_PROCESS_FRONT_PICTURE,
    PHASE_PROCESS_SIDE_PICTURE
  ]

  const mapToLanguage = (code: string): Language => {
    switch (code) {
        case 'en':
            return 'en';
        case 'fi':
            return 'fi';
        case 'sw':
            return 'sw';
        default:
            return 'en';
    }
  };

  return (
    <div>
      <ConfirmationPopup handleClose={handlePopupClose} handleOnClick={handleOnClick} open={open} />
      <NavBarIcons>
        <Image onClick={goToHome} src={LogoImages} alt='logo-image' />
        <ChangeLanguage>
          {!user && <LoginButton t={t} />}
          <DeskTopView>
            <LanguageDropdown>
              <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                {i18n.language.includes('en') ? 'EN' : i18n.language.includes('fi') ? 'FI' : 'SW'}
              </DropdownButton>
              <DropdownContent open={isOpen}>
                <DropdownItem onClick={() => handleLanguageChange('en')}>English</DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange('fi')}>Finnish</DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange('sw')}>Swedish</DropdownItem>
              </DropdownContent>
            </LanguageDropdown>
            <ThemeChange>
              <Image width={18} height={18} src={theme.name === 'Light' ? Moon : Sun} alt='theme' onClick={handleDarkTheme} />
            </ThemeChange>
          </DeskTopView>
          {user && <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className="menu-btn" startIcon={<MenuIcon style={{ fontSize: '30px' }} />} />}
        </ChangeLanguage>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          style={{ top: '60px' }}
        >
          {MENU_ITEMS.filter(d => d.phase === SIZEY_SHOP ? shopUrl.length > 0 : true).map((val) => <MenuItem>
            <ListSection onClick={() => !open && openConfirmationPopupFor.includes(currentProcess) ? handleOpen(val.phase) : handleChange(val.phase)}>
              <Image width={15} height={15} src={theme[val.icon]} alt='logo-image' />
              <Label>{t(val.label)}</Label>
            </ListSection>
          </MenuItem>)}
          <MobileView>
            {MOBILE_MENU.map((val) => (
              <MenuItem key={val.label}>
                <ListSection onClick={() => val.handleClick()}>
                  <Image width={15} height={15} src={theme[val.icon]} alt='logo-image' />
                  <Label>{val.label}</Label>
                  {isInnerDropdownOpen &&

                    <DropdownChild $leftvalue={leftValues[selectedLanguage]}>
                      {val.subMenu && val.subMenu.map((subMenuItem) =>
                      (
                        <SideDropdown key={subMenuItem.label} onClick={() => handleLanguageChange(mapToLanguage(subMenuItem.languageCode))}>
                          <Label>{subMenuItem.label}</Label>
                        </SideDropdown>

                      )
                      )}
                    </DropdownChild>
                  }
                </ListSection>
              </MenuItem>
            ))}
          </MobileView>
          {user && <MenuItem>
            <ListSection onClick={() => handleLogout()}>
              <Image width={15} height={15} src={theme.logoutIcon} alt='logo-image' />
              <Label>{t('logout')}</Label>
            </ListSection>
          </MenuItem>}
        </Menu>
      </NavBarIcons>
    </div>

  )
}

export default Navbar;