import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';

function ThreeDMesh({ ...props }) {
  const group = useRef()
  console.log("*** ", props.meshUri);

  const { nodes } = useGLTF(props.meshUri)//'https://storage.googleapis.com/sizey-vroom/avatars/f_sz_mid_n34/Deborah_f_Arms%20Down.glb'
  return (
    <group ref={group} {...props} dispose={null}>
      {Object.keys(nodes).map((node, i) => {
        if (nodes[node].type === 'Mesh') {
          const material = nodes[node].material.clone();
          material.color = new THREE.Color(1.5, 1.5, 1.5);
          return (
            <mesh key={i} geometry={nodes[node].geometry} material={material} />
          );
        }
          return '';
      })}
    </group>
  )
}

export default ThreeDMesh;
