import React, { useRef, useCallback, useState, useEffect } from "react";
import Webcam from "react-webcam";
// eslint-disable-next-line
import * as tf from "@tensorflow/tfjs";
// import '@tensorflow/tfjs-backend-webgl';
import * as moveNet from "@tensorflow-models/pose-detection";
import './Camera.css';
import { useDispatch } from "react-redux";
import { PHASE_FRONT_VIEW_INSTRUCTIONS, setShowPhase } from "../../../actions/recommendationAction";
import { useTranslation } from "react-i18next";

var FrontPoseSvg = require("./assets/front-view-dashed.svg");
var SidePoseSvg = require("./assets/side-view-dashed.svg");

const ORIENTATION_FRONT = "front";
const ORIENTATION_SIDE = "side";
// One detection must maximally take this many seconds
const MAX_CYCLE_TIME = 150;
// If cycles are too slow for this many times, switch to simpler view
const MAX_CYCLES_EXCEEDED = 5;

// Head/Ankles must be detected with this confidence level
const MIN_DETECTION_SCORE = 0.5;
// We detect this much keypoints for drawing the skeleton more smoothly
const ANIMATION_BUFFER_SIZE = 2;
const VALID_LEFT_ARM_ANGLE = { front: 0.785, side: 1.57 };
const VALID_RIGHT_ARM_ANGLE = { front: -0.785, side: 1.57 };
const VALID_LEFT_LEG_ANGLE = { front: 1.45, side: 1.57 };
const VALID_RIGHT_LEG_ANGLE = { front: -1.45, side: 1.57 };

function containsEmpty(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (!(i in arr)) {
      return true;
    }
  }
  return false;
};

function keypointAverages(keypoint_array) {
  // Calculate average numeric values from an array of keypoint arrays
  let averageArray = [];
  for (let i = 0; i < keypoint_array.length; i++) {
    let sub_array = keypoint_array[i];
    for (let j = 0; j < sub_array.length; j++) {
      if (typeof (averageArray[j] !== undefined) && i > 0) {
        // average on the fly, formula is basically
        // avg = avg + (new_value - avg) / n
        averageArray[j].score = averageArray[j].score + (sub_array[j].score - averageArray[j].score) / (i + 1);
        averageArray[j].x = averageArray[j].x + (sub_array[j].x - averageArray[j].x) / (i + 1);
        averageArray[j].y = averageArray[j].y + (sub_array[j].y - averageArray[j].y) / (i + 1);
      }
      else {
        averageArray[j] = sub_array[j];
      }
    }
  }
  return averageArray;
};

function factorDistance(x0, y0, x1, y1, f) {
  // Given points x0, y0 and x1, y1, find point at a certain distance along the line
  // f is a factor of the distance between x0, y0 and x1, y1, i.e. 2 notes for 2x distance
  // return value based on how close the angle is to the expected value
  // solved using formula on https://math.stackexchange.com/a/2109383 (trigonometry)
  // result_x = x1 - (d2 * (x1-x0))/d
  // result_y = y1 - (d2 * (y1-y0))/d
  // where d is Math.sqrt(Math.pow(x1-x0) + (Math.pow(y1-y0)))
  // and d2 is the distance we are looking for
  // However, as d2 is f * d, we can simplify:
  const result_x = x0 - (f * (x0 - x1))
  const result_y = y0 - (f * (y0 - y1))
  return { x: result_x, y: result_y };
};

function getAngle(keypoint1, keypoint2) {
  // This is the angle between two points in radians
  // arctan((y2-y1)/(x2-x1))
  if (keypoint1.x === keypoint2.x) {
    return (0.5 * Math.pi).toFixed(2);
  }
  else {
    const angle = (
      Math.atan(
        (keypoint1.y - keypoint2.y) / (keypoint1.x - keypoint2.x)
      )
    ).toFixed(2);
    // For a 45 degree angle, abs(k) = 1, and atan ~= 0.785
    return angle;
  }
};
// For performance, this should be defined outside 'Camera'
function isValidAngle(angle, expected_angle) {
  return (angle > (expected_angle - 0.17) && angle < (expected_angle + 0.17))
};

const Camera = props => {
  const orientation = props.orientation;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let validLeftArmAngle;
  let validRightArmAngle;
  let validLeftLegAngle;
  let validRightLegAngle;

  if (orientation === ORIENTATION_FRONT) {
    validLeftArmAngle = VALID_LEFT_ARM_ANGLE.front;
    validRightArmAngle = VALID_RIGHT_ARM_ANGLE.front;
    validLeftLegAngle = VALID_LEFT_LEG_ANGLE.front;
    validRightLegAngle = VALID_RIGHT_LEG_ANGLE.front;
  }
  else if (orientation === ORIENTATION_SIDE) {
    validLeftArmAngle = VALID_LEFT_ARM_ANGLE.side;
    validRightArmAngle = VALID_RIGHT_ARM_ANGLE.side;
    validLeftLegAngle = VALID_LEFT_LEG_ANGLE.side;
    validRightLegAngle = VALID_RIGHT_LEG_ANGLE.side;
  }

  // model used in pose detection
  const [moveNetModel, setMoveNetModel] = useState(null);
  const initialModelRender = useRef(true);
  // whether model is currently predicting
  const [isDetecting, setIsDetecting] = useState(false);
  // this is used in detecting slow devices
  const maxCyclesExceeded = useRef(0);
  // reference to webcam
  const webcamRef = useRef(null);
  // reference to HTML5 canvas
  const canvasRef = useRef(null);

  // Array of body keypoints over time
  const posePoints = useRef(
    new Array(ANIMATION_BUFFER_SIZE)
  );

  // This is the state that defines whether to take picture
  const [isValidPos, setIsValidPos] = useState(false);

  // Valid position triggers the capture
  useEffect(() => {
    if (isValidPos && !takingPicture) {
      capture();
    }
    // eslint-disable-next-line
  }, [isValidPos]);
  // whether we are currently taking picture
  const [takingPicture, setTakingPicture] = useState(false)

  // slow device changes manual
  const [slowDeviceDetected, setSlowDeviceDetected] = useState(false);
  const [timerId, setTimerId] = useState(null)

  // camera orientation: user / environment
  const [facingMode, setFacingMode] = useState("user");
  // timer shown while taking picture
  const [timer, setTimer] = useState(5);

  // https://blog.jakuba.net/request-animation-frame-and-use-effect-vs-use-layout-effect/

  const render = () => {
    const updateScreen = () => {

      const startTime = (performance || Date).now();

      if (!isDetecting && webCamReady()) {
        detectBodySegments();
      }
      // i.e. if not all posePoints are yet measured
      if (
        !posePoints.current.includes(undefined) &&
        // Firefox desktop errors without below check
        !containsEmpty(posePoints.current) &&
        webCamReady()

      ) {
        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;
        webcamRef.current.video.width = videoWidth;
        webcamRef.current.video.height = videoHeight;
        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;
        const canvas = canvasRef.current;

        // average of body positions over time
        const averagePosePoints = keypointAverages(posePoints.current);


        const isWholeBodyOnScreen = (
          // nose
          averagePosePoints[0].score > MIN_DETECTION_SCORE &&
          // ankles
          averagePosePoints[15].score > MIN_DETECTION_SCORE &&
          averagePosePoints[16].score > MIN_DETECTION_SCORE
        );

        const leftShoulderPos = averagePosePoints[5];
        // Right shoulder
        const rightShoulderPos = averagePosePoints[6];
        // Left elbow
        const leftElbowPos = averagePosePoints[7];
        // Right elbow
        const rightElbowPos = averagePosePoints[8];
        // Left Hip
        const leftHipPos = averagePosePoints[11];
        // Right Hip
        const rightHipPos = averagePosePoints[12];
        // Left knee
        const leftKneePos = averagePosePoints[13];
        // Right knee
        const rightKneePos = averagePosePoints[14];

        const leftArmAngle = getAngle(leftElbowPos, leftShoulderPos);
        const rightArmAngle = getAngle(rightElbowPos, rightShoulderPos);
        const leftLegAngle = getAngle(leftKneePos, leftHipPos);
        const rightLegAngle = getAngle(rightKneePos, rightHipPos);
        const leftArmEndPos = factorDistance(leftShoulderPos.x, leftShoulderPos.y, leftElbowPos.x, leftElbowPos.y, 2);
        const rightArmEndPos = factorDistance(rightShoulderPos.x, rightShoulderPos.y, rightElbowPos.x, rightElbowPos.y, 2);
        const leftLegEndPos = factorDistance(leftHipPos.x, leftHipPos.y, leftKneePos.x, leftKneePos.y, 2);
        const rightLegEndPos = factorDistance(rightHipPos.x, rightHipPos.y, rightKneePos.x, rightKneePos.y, 2);

        const validLeftArm = isValidAngle(leftArmAngle, validLeftArmAngle);
        const validRightArm = isValidAngle(rightArmAngle, validRightArmAngle);
        const validLeftLeg = isValidAngle(leftLegAngle, validLeftLegAngle);
        const validRightLeg = isValidAngle(rightLegAngle, validRightLegAngle);


        const ctx = canvas.getContext('2d');

        // Body
        ctx.beginPath();
        ctx.moveTo(leftShoulderPos.x, leftShoulderPos.y);
        ctx.lineTo(rightShoulderPos.x, rightShoulderPos.y);
        ctx.lineTo(rightHipPos.x, rightHipPos.y);
        ctx.lineTo(leftHipPos.x, leftHipPos.y);
        ctx.lineTo(leftShoulderPos.x, leftShoulderPos.y);
        ctx.strokeStyle = 'white';
        ctx.lineWidth = '20';
        ctx.stroke();

        // Left Arm
        ctx.beginPath();
        ctx.moveTo(leftShoulderPos.x, leftShoulderPos.y);
        ctx.lineTo(leftArmEndPos.x, leftArmEndPos.y);
        ctx.strokeStyle = validLeftArm ? 'green' : 'red';
        ctx.stroke();

        // Right Arm
        ctx.beginPath();
        ctx.moveTo(rightShoulderPos.x, rightShoulderPos.y);
        ctx.lineTo(rightArmEndPos.x, rightArmEndPos.y);
        ctx.strokeStyle = validRightArm ? 'green' : 'red';
        ctx.stroke();

        // Left Leg
        ctx.beginPath();
        ctx.moveTo(leftHipPos.x, leftHipPos.y);
        ctx.lineTo(leftLegEndPos.x, leftLegEndPos.y);
        ctx.strokeStyle = validLeftLeg ? 'green' : 'red';
        ctx.stroke();

        // Right Leg
        ctx.beginPath();
        ctx.moveTo(rightHipPos.x, rightHipPos.y);
        ctx.lineTo(rightLegEndPos.x, rightLegEndPos.y);
        ctx.strokeStyle = validRightLeg ? 'green' : 'red';
        ctx.stroke();

        if (isWholeBodyOnScreen) {
          // We draw the skeleton but do not set the valid pose if the whole body is not on screen
          if (orientation === ORIENTATION_FRONT) {

            setIsValidPos(
              validLeftArm &&
              validRightArm &&
              validLeftLeg &&
              validRightLeg
            );
          }
          // In side view, we only compare right side limbs
          else if (orientation === ORIENTATION_SIDE) {
            setIsValidPos(
              validRightArm &&
              validRightLeg
            );

          }
        }
        const endTime = (performance || Date).now();

        if (((endTime - startTime) > MAX_CYCLE_TIME)) {
          maxCyclesExceeded.current += 1;
          if ((maxCyclesExceeded.current >= MAX_CYCLES_EXCEEDED) || (endTime - startTime > 5 * MAX_CYCLE_TIME && maxCyclesExceeded.current > 2)) {
            maxCyclesExceeded.current = MAX_CYCLES_EXCEEDED + 1;
          }
        }
      }
      if (takingPicture) {
        return Promise.resolve();
      }
      if (maxCyclesExceeded.current < MAX_CYCLES_EXCEEDED) {
        return new Promise(resolve => {
          requestAnimationFrame(resolve);
        }).then(updateScreen);
      }
      else {
        return Promise.resolve().then(setSlowDeviceDetected(slowDeviceDetected => true));
      }
    }
    return updateScreen();
  }

  useEffect(() => {
    // This function loads the pose detection model (MoveNet)
    const loadMoveNetModel = async () => {

      const network = await moveNet.createDetector(moveNet.SupportedModels.MoveNet, { modelType: moveNet.movenet.modelType.SINGLEPOSE_LIGHTNING });
      setMoveNetModel(network);
      console.log("MoveNet model loaded.");
    }
    if (props.network === null) {
      loadMoveNetModel();
    }
    else { setMoveNetModel(props.network); }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (initialModelRender.current) { initialModelRender.current = false; }
    else { render(); }
    // eslint-disable-next-line
  }, [moveNetModel]);

  const webCamReady = () => {
    return (typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video !== null &&
      webcamRef.current.video.readyState === 4
    )
  }

  // actual prediction of body segements, costly
  const detectBodySegments = async () => {
    // Check if webcam input available
    if (
      webCamReady() &&
      moveNetModel !== null
    ) {
      setIsDetecting(true);
      // Even if the line before checks that the webcam is ready it might still fail
      try {
        const video = webcamRef.current.video;
        // Body holds the segmentation information
        const poses = await moveNetModel.estimatePoses(video).then(setIsDetecting(false));
        // keypoints correspond to locations of different body parts, returns score (confidence), name and x,y pixel coordinates
        // 0: 'nose'
        // 1: 'leftEye'
        // 2: 'rightEye'
        // 3: 'leftEar'
        // 4: 'rightEar'
        // 5: 'leftShoulder'
        // 6: 'rightShoulder'
        // 7: 'leftElbow'
        // 8: 'rightElbow'
        // 9: 'leftWrist'
        // 10: 'rightWrist'
        // 11: 'leftHip'
        // 12: 'rightHip'
        // 13: 'leftKnee'
        // 14: 'rightKnee'
        // 15: 'leftAnkle'
        // 16: 'rightAnkle'
        if (poses !== undefined &&
          poses.length !== 0 &&
          poses[0] !== undefined &&
          poses[0].score > 0.4
        ) {
          // Put the most current keypoints in the beginning of the array and remove the last element
          posePoints.current = [poses[0].keypoints].concat(posePoints.current.slice(0, ANIMATION_BUFFER_SIZE));
        }
      }
      catch { setIsDetecting(false); }
    }
  }
  const capture = useCallback(() => {
    setTakingPicture(true);
    setTimerId(setInterval(function () { setTimer(timer => timer - 1); }, 1000));
    // eslint-disable-next-line
  }, [webcamRef]);

  useEffect(() => {
    if (timer <= 0) {
      clearInterval(timerId);
      const imageSrc = webcamRef.current.getScreenshot();
      setTakingPicture(false);
      props.onCapture(imageSrc);
    }
    // eslint-disable-next-line
  }, [timer]);

  const handleClick = useCallback(() => {
    setFacingMode(facingMode =>
      facingMode === "environment" ? "user" : "environment"
    );
  }, []);

  return (
    <div css={{ position: 'absolute', margin: 0, top: '0%', left: '0%', right: '0%', bottom: '0%', padding: 0, width: '100%', height: '100%', paddingTop: '0%' }}>
      <div css={{
        color: "rgb(255, 255, 255)",
        position: "relative",
        margin: "0 auto",
        width: "100%",
        maxWidth: "1000px",
        textAlign: "center",
      }}
      >
        <Webcam
          audio={false}
          mirrored={facingMode === "user"}
          width="100%"
          key={facingMode}
          videoConstraints={{ facingMode: facingMode }}
          screenshotFormat="image/jpeg"
          ref={webcamRef}
          className="web_camera"
        />
        {!slowDeviceDetected && <canvas
          ref={canvasRef}
          width="100%"
          className="web_camera"
          style={{
            transform: facingMode === "user" ? "scale(-1, 1)" : "scale(1, 1)",
          }}
        />

        }
        <div className="close_image">
          <img
            src={require("./assets/close-camera.svg").default}
            alt=""
            className="close_svg"
            onClick={() => dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS))}
          />
        </div>
        <div style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          fontSize: "40vh",
          top: 0,
          left: 0,
          zIndex: 3,
        }}>
          <div style={{
            color: "white",
            top: "50%",
            left: "50%",
            transform: "translateY(-50%)",
          }}>
            {takingPicture && <p>{timer}</p>}
          </div>

          {(webCamReady() && !takingPicture) && <img
            alt="pose_svg"
            src={orientation === ORIENTATION_FRONT ? FrontPoseSvg.default : SidePoseSvg.default}
            className="both_pose_svg"
          />}
        </div>
      </div>
      <div className="footer_container">
        <p className="phase_instruction" style={{ color: orientation === ORIENTATION_FRONT ? "#3297ed" : "#04e58c" }}>{slowDeviceDetected ? t(`take`) : t(`taking`)} <b>{t(orientation)}</b> {t(`picture`)} {slowDeviceDetected ? t(`manually`) : t(`automatically`)}.{t(`take_position_picture`)}.</p>
        <div className="button_container">
          <img
            className="capture_image"
            width='30%'
            src={require("./assets/camera.svg").default}
            alt=""
            onClick={handleClick}
          />
          {slowDeviceDetected && <img
            className="capture_image"
            width='30%'
            src={require("./assets/shoot.svg").default}
            alt=""
            onClick={capture}
          />
          }
        </div>
      </div>
    </div>
  );
};

export default Camera;
