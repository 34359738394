import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getUrlQuery, getUserMeasurements, userMeasurement } from '../../actions/UserAction'
import Card from '../../common/Card'
import TableComponent from '../../common/TableComponent'
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import AddIcon from '@material-ui/icons/Add';
import CircumferenceList from './CircumferenceList';
import { Button } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { getTapMeasurement, PHASE_MEASURING, setShowPhase, tapeMeasurement, updateTapeMeasure } from '../../actions/recommendationAction'
import { FirebaseContext } from '../../providers/FirebaseProviders'
import CustomButton from '../../common/CustomButton';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { ThemeContext } from 'styled-components'
import { convertKeyToValue } from '../../common/helper'
import { COLORS } from '../../common/Colors'

const { WHITE, BLUE } = COLORS;

const MeasurementIcons = styled(ToggleButtonGroup)`
    margin-left: 10px;
    height: 30px;
    .MuiToggleButton-root.Mui-selected  {
        background-color: ${({ theme }) => theme.name === 'Light' ? WHITE : BLUE};
    }
    .MuiToggleButton-root {
        border: 1px solid ${({ theme }) => theme.colors.toggleBorder}
    }
`;

const Image: any = styled.img`
  width: 25px;
  height: 24px;
  cursor: pointer;
`;

const CameraIcon = styled(CameraAltIcon)`
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`;

const ButtonSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    .tape-button {
        margin: 0px 10px;
    }
`;

const TapeId = styled.p`
    margin: 0px;
    cursor: pointer;
    display: flex;
    margin-left: 16px;
    width: 100%;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    height: 100px;
`;

function ShowMeasurements(props: any) {
    const { t } = useTranslation()
    const { loginUser } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.userInfo);
    const tapeMeasurementData = useSelector((state: any) => state.recommendationReducer.tapeMeasurement);
    const tapeData = userInfo?.measurements.find((v: any) => v.tape);
    const userMeasurements = userInfo?.measurements.filter((v: any) => v.tape === false);
    const [format, setFormat] = React.useState('dynamic');
    const [units, setUnits] = useState<any>({});
    const [isLoading, setIsLoading] = useState('');
    const authContext = useContext(FirebaseContext);
    const [measurementId, setMeasurementId] = useState('');
    const themeContext = useContext(ThemeContext);
    const url = new URL(window.location.href);
    const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
    const productId: any = url.searchParams.get('productId');

    useEffect(() => {
        const circumferences = convertKeyToValue(tapeMeasurementData?.measurement);
        setUnits(circumferences)
    }, [tapeMeasurementData?.measurement])

    useEffect(() => {
        if (measurementId) {
            dispatch(getTapMeasurement(measurementId));
        }
    }, [measurementId, dispatch])

    const changeUnitValue = (value: any, key: any) => {
        let data: any = { ...units };
        data[key] = value * 10;
        if (value > -1 && /^\d{0,3}$/.test(value)) {
            setUnits(data);
        }
    }

    const openRecommendation = () => {
        const { height, gender } = loginUser;
        const url = getUrlQuery();
        if (url) {
            let redirectUrl = `/measurement${url}height=${(height || '')}&humanGender=${(gender || '')}`
            if (redirectUrl.includes('UPC') || redirectUrl.includes('upc') || redirectUrl.includes('productId')) {
                let parameters = redirectUrl.split('&')
                parameters = parameters.filter(param => !param.startsWith('UPC=')) || parameters.filter(param => !param.startsWith('upc=') || parameters.filter(param => !param.startsWith('productId=')));
                redirectUrl = parameters.join('&');
            }
            navigate(redirectUrl)
        } else {
            navigate(`/measurement?apikey=${process.env.REACT_APP_API_KEY}&height=${(height || '')}&humanGender=${(gender || '')}`)
        }
    }

    const handleToggle = (event: React.MouseEvent<HTMLElement>, format: string) => {
        if (format) {
            setFormat(format);
        }
    };

    const openMeasurementById = (measurementId: string) => {
        const url = getUrlQuery();
        if(!UPC && !productId) {
            dispatch(setShowPhase(PHASE_MEASURING))
        }
        if (url) {
            navigate(`measurement${url}measurementId=${measurementId}`)
        } else {
            navigate(`measurement?apikey=${process.env.REACT_APP_API_KEY}&measurementId=${measurementId}`)
        }
    }

    const copyToClipboard = (text: any) => {
        const textField = document.createElement("textarea");
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        alert("Copied")
    };

    const handleSubmit = async () => {
        const token = await authContext?.user?.getIdToken(true);
        let measurement: any = {};
        Object.keys(units).forEach(key => {
            const [a, b] = key.split('.')
            measurement[b] = measurement[b] || {};
            measurement[b][a] = units[key];
        })
        let measurementId = null;
        setIsLoading('save');
        if (!tapeData) {
            const res: any = await dispatch(tapeMeasurement(measurement));
            if (res) {
                measurementId = res.id;
                await dispatch(userMeasurement({ measurement_id: res.id, tape: true }, token));
                await dispatch(getUserMeasurements(token, navigate));
            }
        } else {
            measurementId = tapeData?.measurement_id;
            await dispatch(updateTapeMeasure(tapeData?.measurement_id, measurement));
        }
        setMeasurementId(measurementId);
        setIsLoading('');
    }

    const handleReset = async () => {
        let measurement: any = {};
        measurement['circumference'] = {};
        if (tapeData) {
            setUnits({});
            setIsLoading('reset');
            await dispatch(updateTapeMeasure(tapeData?.measurement_id, measurement));
            setMeasurementId(tapeData?.measurement_id);
            setIsLoading('');
        }
    }

    const columns = [
        { id: 1, key: 'measurement_id', label: t('measurement_id') },
        { id: 2, key: 'timestamp', label: t('date'), format: 'date' },
        { id: 3, key: 'timestamp', label: t('time'), align: 'right', format: 'time' }
    ];

    const Toggle = () => {
        return <MeasurementIcons exclusive value={format} onChange={handleToggle}>
            <ToggleButton value='tape'>
                <Image src={themeContext?.tapeLogo} alt='tape-image' />
            </ToggleButton>
            <ToggleButton value='dynamic'>
                <CameraIcon />
            </ToggleButton>
        </MeasurementIcons>
    }

    const SizeRecommendation = () => {
        return format === 'tape' && tapeData ?
            <TapeId>
                <span style={{ margin: 0 }} onClick={() => openMeasurementById(tapeData?.measurement_id)}>{tapeData?.measurement_id}</span>
                <FileCopyOutlinedIcon onClick={() => copyToClipboard(tapeData?.measurement_id)} style={{ fontSize: '16px', marginLeft: '5px' }} />
            </TapeId> : <></>

    }

    const ButtonComponent = (props: any) => {
        return (
            <>
                {props.show ? <Button
                    color='primary'
                    variant='contained'
                    onClick={openRecommendation}
                    startIcon={<AddIcon />}
                >{t('generateNewMeasurement')}</Button> :
                    <ButtonSection>
                        <CustomButton color="primary" className="tape-button" variant='contained' name='Reset' isloading={isLoading === 'reset'} handleClick={handleReset} />
                        <CustomButton color="primary" className="tape-button" variant='contained' name='Save' isloading={isLoading === 'save'} handleClick={handleSubmit} />
                    </ButtonSection>}
            </>
        )
    }

    return (
        <Card
            heading={(format === 'tape' ? t(`manualMeasurements`) : t(`myMeasurements`)) + ' (cm)'}
            showButton={format === 'dynamic'}
            measurementIcons={<Toggle />}
            sizeRecommendation={<SizeRecommendation />}
            button={<ButtonComponent show={format === 'dynamic'} />}
        >
            {format === 'dynamic' && <TableComponent columns={columns} rows={userMeasurements} handleOnClick={openMeasurementById} />}
            {format === 'tape' && <CircumferenceList changeUnitValue={changeUnitValue} units={units} userDetail={userInfo.user} />}
        </Card>
    )
}

export default ShowMeasurements
