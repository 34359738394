import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.text};
  justify-content: center;
`;

function MissingApiKey() {
  const { t } = useTranslation()
  return <Container>{t("Missing API key!")}</Container>;
}

export default MissingApiKey;
