import React, { useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Pictures from "../../assets/images/uploadImage.svg";
import { captureImage } from "../../actions/recommendationAction";
import { useTranslation } from "react-i18next";
import { FirebaseContext } from "../../providers/FirebaseProviders";

const UploadImageIcon: any = styled.img<{ $user: any, $language: string }>`
  position: absolute;
  z-index: 99;
  top: 25px;
  right: ${(props: any) => !props.$user ? "410px" : "250px"};
  cursor: pointer;
  max-width: 35px;
  .RDVaH {
    width: 100% !important;
  }
  
  @media (max-width: 600px) {
    top: 25px;
    right: ${(props: any) => !props.$user ? "220px" : "80px"};
    max-width: 26px;
  }
`;

const fileTypes = ["image/png", "image/jpeg"];

const UploadImage = ({ orientation, setShowError }: any) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { user } = useContext(FirebaseContext);


  return (
    <div>
      <label htmlFor="icon-button-file">
        <UploadImageIcon $user={user} $language={i18n.language} src={Pictures} alt="" />
      </label>
      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/png,image/jpeg"
        id="icon-button-file"
        onChange={(e: any) => {
          const file = e.currentTarget.files[0];
          const reader = new FileReader();
          const fileExtension = fileTypes.includes(file?.type);
          reader.addEventListener(
            "load",
            () => {
              !fileExtension &&
                setShowError(t("allowed_file_types"));
              dispatch(
                captureImage({
                  image: reader.result,
                  orientation,
                  fileExtension,
                })
              );
            },
            false
          );
          file && reader.readAsDataURL(file);
        }}
      />
    </div>
  );
};

export default UploadImage;
