import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import styled from "styled-components";

const Title = styled(DialogTitle)`
    color: ${({ theme }) => theme.colors.color};
`;

const Text = styled(DialogContentText)`
    color: ${({ theme }) => theme.colors.color};
`;

const CustomButton = styled(Button)`
    color: ${({ theme }) => theme.colors.color};
`;

export default function ConfirmationPopup(props: any) {
    const { handleClose, open, handleOnClick } = props;
    const { t } = useTranslation()

    const handleOkClick = () => {
        handleClose()
        handleOnClick()
    }
    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <Title style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {t(`silhouette_progress`)}
                </Title>
                <DialogContent>
                    <Text>
                        {t(`progress_message`)}
                    </Text>
                </DialogContent>
                <DialogActions>
                    <CustomButton autoFocus onClick={handleClose} color="primary">
                        {t(`cancel`)}
                    </CustomButton>
                    <CustomButton onClick={handleOkClick} color="primary">
                        Ok
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}
