
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { tapeMeasurementApi, updateTapeMeasureApi } from '../api/recommendation-api'
import { setRecommendations } from '../actions/recommendationAction'
import { COLORS } from '../common/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { FirebaseContext } from '../providers/FirebaseProviders'

const { BLUE } = COLORS;

const SaveButton = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
`;

const Heading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 17px 0px 0px 0px;
`;

const SubHeading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    text-transform: uppercase;
`;

const SaveContainer = styled.div`
    position: absolute;
    bottom: 0px;
    width: 100%;
    @media (max-width: 600px) {
        border-top: 2px solid ${BLUE};
    }
`;

const SizeChartResult = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const location = useLocation();
    const authContext = useContext(FirebaseContext);
    const navigate = useNavigate();
    const search = (location.search).replace('?', '');
    const url = new URL(window.location.href);
    const apiKey: any = url.searchParams.get("apikey");
    const paramsMeasurementId = url.searchParams.get("measurementId");
    const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
    const productId: any = url.searchParams.get('productId');
    const [showMessage, setMessage] = useState(false);

    const { recommendations, measurements, measurementFailed } = useSelector((state: any) => state.recommendationReducer);

    const recommendation = recommendations && recommendations.length > 0 ? recommendations[0] : null;

    const postMessage = async () => {
        if (!measurementFailed) {
            await eventPostMessage(targetWindow);
        }
    }

    const targetWindow = (message: any, hosts: string) => {
        if (window.opener) {
            window.opener.postMessage(message, hosts);
        }
        window.postMessage(message, hosts);
    };

    useEffect(() => {
        if(recommendations && measurements){
            postMessage();
        }
        // eslint-disable-next-line
    }, [recommendations, measurements]);

    const eventPostMessage = async (windowPostMessage: any) => {
        await fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}apikey`, {
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Sizey-Key": apiKey,
            }),
        })
            .then((doc) => doc.json())
            .then((doc) => {
                windowPostMessage(
                    { event: "sizey-measurements", measurements },
                    doc.hosts
                );
                windowPostMessage(
                    { event: "sizey-recommendation", recommendation: recommendation },
                    doc.hosts
                );
                windowPostMessage(
                    {
                        event: "sizey-recommendations",
                        recommendations: recommendations,
                    },
                    doc.hosts
                );
            });
    };


    const handleOnClick = async () => {
        if (paramsMeasurementId && paramsMeasurementId !== 'null' && paramsMeasurementId !== 'undefined') {
            const tapeMeasurement = await updateTapeMeasureApi(paramsMeasurementId, measurements);
            if (tapeMeasurement) {
                setMessage(true)
            }
        } else {
            const tapeMeasurement = await tapeMeasurementApi({ ...measurements });
            let replaceMeasurementId = '';
            if (search.includes('null')) {
                replaceMeasurementId = search.replace('null', `${tapeMeasurement.id}&saved`)
            } else {
                replaceMeasurementId = search + `measurementId=${tapeMeasurement.id}&saved`
            }
            window.location.href = `${process.env.REACT_APP_LOGIN_URL}&${replaceMeasurementId}`
        }
    }

    const reducingReturn = () => {
        if (window.opener === null) {
            dispatch(setRecommendations(undefined))
            if (!!authContext.user && (!!UPC || !!productId )) {
                navigate(`/${url.search}`);
            }
        } else {
            window.close();
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Heading >{t('size_recommendation')}</Heading>
            <Heading >{t('size')} - {recommendations[0].sizes?.map((s: any) => s.size)}</Heading>
            <Button type='submit' className="btn-outlined" variant='outlined' color='primary'
                onClick={reducingReturn}
            >
                {t('go_back')}
            </Button>
            {showMessage && <SubHeading style={{color: 'green', marginTop: '30px'}}>{'Measurements successfully updated!'}</SubHeading>}
            <SaveContainer>
                <SaveButton onClick={handleOnClick}>{t('save_measurements')}</SaveButton>
                <SubHeading>{t('reducingReturn')}</SubHeading>
            </SaveContainer>
        </div>
    )
}

export default SizeChartResult;