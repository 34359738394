/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { Html, Loader, OrbitControls } from '@react-three/drei'
import ThreeDMesh from './ThreeDMesh'
import styled from 'styled-components'

const Container = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

function Rig({ children }) {

  const outer = useRef()
  const inner = useRef()
  return (
    <group position={[0, -100, 0]} ref={outer}>
      <group ref={inner}>{children}</group>
    </group>
  )
}


function CanvasInfo(modelUri) {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Canvas style={{ 
      width: isMobile ? '65%' : '75%',
      height: isMobile ? '300px' : '450px'
     }}
      camera={{ position: [0.0010000000474974513, 101, 380], fov: 28.000000209988553 }}
      onCreated={({ gl, camera }) => {
        camera.lookAt(0, 0, 0)
      }}
    >
      <directionalLight position={[0, 10, 10]} intensity={2} />
      <pointLight position={[0, 100, 250]} />
      <Suspense
        fallback={
          <Html center>
            <Loader />
          </Html>
        }>
        <Rig>
          <ThreeDMesh meshUri={modelUri} />
          <OrbitControls />
        </Rig>
      </Suspense>
    </Canvas>
  )
}

export default function ThreeDModel({ modelUri }) {
  return (
    <Container>
      {modelUri ? CanvasInfo(modelUri) : <span>-</span>}
    </Container>
  )
}
