import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Picture from "../Picture";
import {
  setShowPhase,
  submitMeasurements,
  PHASE_FRONT_VIEW_INSTRUCTIONS,
} from "../../../actions/recommendationAction";

const Container = styled.div`
  width: 100%;
  margin: 40px 0px;
`;

const ChildrenContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const NextButton = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  margin-top: 1em;
  justify-content: center;
  .MuiButton-containedPrimary {
    margin: 0px 30px;
  }
`;

const ReviewSilhouette = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 20px;
  font-weight: 700;
  margin-top: 1em;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const TryButton = styled.div`
  margin-top: 1em;
  margin: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
`;

const PicturesContainer = styled.div`
  display: flex;
  width: 45%;
  @media (max-width: 600px) {
    width: 95%;
  }
`;

const FailedWrapper = styled.div`
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: 50%;
  color: ${({ theme }) => theme.colors.text};
  min-height: 70vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    line-height: 40px;
  }
  .MuiButton-containedPrimary {
    max-width: 120px;
    height: 48px;
    width: 100%;
    margin-left: 20px;
    font-weight: bold;
    border-radius: 20px;
  }
`;

const ViewReview = (props: any) => {
  const dispatch = useDispatch();
  const { children, frontPicture, sidePicture } = props;
  const { t } = useTranslation();


  return (
    <Container>
      {(frontPicture && sidePicture) ?
        <>
          <ChildrenContainer>
            {children}
            <PicturesContainer>
              <Picture picture={frontPicture?.image} />
              <Picture picture={sidePicture?.image} />
            </PicturesContainer>
          </ChildrenContainer>
          <ReviewSilhouette>{t("reviewSilhouette")}</ReviewSilhouette>
          <NextButton>
            <TryButton
              onClick={() => {
                dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS));
              }}
            >
              {t("Try Again")}
            </TryButton>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => dispatch(submitMeasurements())}
            >
              {t("next")}
            </Button>
          </NextButton>
        </>
        :
        <FailedWrapper>
          {t('silhouetteFailed')}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS));
            }}
          >
            {t("Try Again")}
          </Button>
        </FailedWrapper>
      }
    </Container>
  );
};

export default ViewReview;
