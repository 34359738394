import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import styled from 'styled-components';

const Container = styled(Paper)`
    min-height: 380px;
    .MuiTable-stickyHeader {
        background-color: ${({ theme }) => theme.colors.card};
    }
    .MuiTableCell-body {
        color: ${({ theme }) => theme.colors.color};
        border-bottom: 0px;
        @media (max-width: 600px) {
            padding: 12px;
        }
    }
    .MuiTableCell-stickyHeader  {
       background-color: ${({ theme }) => theme.colors.card};
       color: ${({ theme }) => theme.colors.color};
    }
    .makeStyles-container-2 {
        max-height: 280px;
    }
`;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 365, // Adjust the height for mobile devices
          },
          [theme.breakpoints.up('sm')]: {
            maxHeight: 350, // Height for larger screens
          },
    },
    row: {
        cursor: 'pointer'
    }
}));

function TableComponent(props: any) {
    const classes = useStyles();

    const { columns, rows, handleOnClick } = props;

    return (
        <Container className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.length > 0 && columns.map((column: any) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 && rows.map((row: any) => {
                            return (
                                <TableRow hover tabIndex={-1} key={row.code}>
                                    {columns.map((column: any) => {
                                        const value = row[column.key];
                                        return (
                                            <TableCell className={classes.row} key={column.id} align={column.align} onClick={() => handleOnClick(row.measurement_id)}>
                                                {column.format === 'date' ? moment(value).format('MM/DD/YYYY')
                                                    : column.format === 'time' ? moment(value).format('h:mm:ss') : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default TableComponent;