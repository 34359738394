import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ViewImage = (props: any) => {
  return (
    <img
      style={{
        margin: "auto",
        marginBottom: 0,
        display: "block",
      }}
      alt="View_image"
      {...props}
    />
  );
};


const Picture = ({ picture, processing = false }: { picture: string, processing?: boolean }) => {
  return (
    <div
      style={{
        width: "50%",
        position: "relative"
      }}
    >
      <ViewImage
        style={{ border: "1px solid rgb(0, 0, 0)" }}
        width="100%"
        src={picture}
      />

      {processing && (
        <div
          style={{
            position: "relative",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            backgroundColor: "rgba(1, 77, 124, .5)",
            alignItems: "center",
            justifyContent: "center",
            color: "rgb(255, 255, 255)",
            fontSize: "3em",
          }}
        >
          <FontAwesomeIcon icon={["fas", "spinner"]} pulse />
        </div>
      )}
    </div>
  );
};

export default Picture;
