import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components'

const Form = styled(FormGroup)`
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    .MuiCheckbox-root {
        color: ${({theme}) => theme.colors.color};
    }
    .MuiFormControlLabel-root {
        margin: 0px;
    }
    .MuiTypography-body1 {
        font-size: 14px;
    }
`;

export default function ReduxCheckBox(props: any) {
    const { input, label, defaultValue } = props;
    return (
        <Form aria-label="position" {...input} row>
            <FormControlLabel
                value={input.value}
                control={<Checkbox checked={defaultValue} color="primary" />}
                label={label}
                labelPlacement="end"
            />
        </Form>
    );
}
