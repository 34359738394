import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { NativeSelect } from '@material-ui/core';

const Container = styled.div``;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function ReduxSelectBox(props: any) {
    const classes = useStyles();
    const { input, helperText, defaultValue, values } = props;

    return (
        <Container>
            <FormControl className={classes.formControl} {...input}>
                <NativeSelect
                    defaultValue={defaultValue}
                    inputProps={{ name: 'name', id: 'uncontrolled-native' }}
                >
                    {values.length > 0 && values.map((v: any) => {
                        return <option value={v.value}>{v.label}</option>
                    })}

                </NativeSelect>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </Container >
    )
}

export default ReduxSelectBox
