import {
  CLEAR_MEASUREMENTS_DATA,
  MEASUREMENT_FAILED,
  REQUEST_PICTURE,
  SET_CAMERA_TIMER,
  SET_FRONT_PICTURE,
  SET_GENDER,
  SET_HEIGHT,
  SET_WEIGHT,
  SET_MEASUREMENTS,
  SET_PHASE,
  SET_RECOMMENDATIONS,
  SET_SIDE_PICTURE,
  TAKE_PICTURE,
  CLEAR_BOTH_PICTURES_DATA,
  SET_MEASUREMENT_FAILED_MESSAGE,
  SET_SIZE_CHART,
  SET_MEASUREMENT_USING_PRODUCTID,
  SET_TAPE_MEASUREMENT,
  SET_BACK_PHASE,
  SET_HOME_CARD,
  SIZEY_PROFILE,
  SET_SLIDER_VALUE
} from "../actions/recommendationAction";

const initialState = {
  frontPicture: null,
  sidePicture: null,
  loading: 0,
  phase: "",
  measurementFailedMsg: "Measuring failed",
  takingPicture: false,
  cameraTimer: null,
  gender: null,
  height: null,
  weight: null,
  showSilhouette: false,
  measurements: undefined,
  measurementFailed: false,
  recommendations: undefined,
  sizeChart: undefined,
  isMeasurement: false,
  isValidModelPath: false,
  tapeMeasurement: {},
  backPhase: '',
  homePhase: SIZEY_PROFILE,
  sliderValue: {}
};

const recommendationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PHASE:
      return {
        ...state,
        phase: action.phase,
      };

    case SET_MEASUREMENT_FAILED_MESSAGE:
      return {
        ...state,
        measurementFailedMsg: action.payload,
      };


    case SET_MEASUREMENTS:
      return {
        ...state,
        measurements: action.payload,
      };

    case SET_GENDER:
      return {
        ...state,
        gender: action.payload,
      };

    case MEASUREMENT_FAILED:
      return {
        ...state,
        measurementFailed: action.payload,
      };

    case TAKE_PICTURE:
      return {
        ...state,
        takingPicture: action.payload,
      };

    case REQUEST_PICTURE:
      return {
        ...state,
        takingPicture: true,
      };

    case SET_RECOMMENDATIONS:
      return {
        ...state,
        recommendations: action.payload,
      };

    case SET_HEIGHT:
      return {
        ...state,
        height: action.payload,
      };

    case SET_WEIGHT:
      return {
        ...state,
        weight: action.payload,
      };

    case SET_FRONT_PICTURE:
      return {
        ...state,
        frontPicture: action.payload,
      };

    case SET_SIDE_PICTURE:
      return {
        ...state,
        sidePicture: action.payload,
      };

    case SET_CAMERA_TIMER:
      return {
        ...state,
        cameraTimer: action.payload,
      };

    case CLEAR_BOTH_PICTURES_DATA:
      return {
        ...state,
        sidePicture: null,
        frontPicture: null
      };

    case CLEAR_MEASUREMENTS_DATA:
      return {
        ...state,
        measurements: undefined,
        recommendations: undefined,
        returnSuccessURl: undefined,
        measurementFailed: false,
      };

    case SET_SIZE_CHART:
      return {
        ...state,
        sizeChart: action.payload,
      };
    case SET_MEASUREMENT_USING_PRODUCTID:
      return {
        ...state,
        isMeasurement: action.payload
      };
    case SET_TAPE_MEASUREMENT:
      return {
        ...state,
        tapeMeasurement: action.payload
      }
    case SET_BACK_PHASE:
      return {
        ...state,
        backPhase: action.backPhase,
      };
    case SET_HOME_CARD:
      return {
        ...state,
        homePhase: action.payload
      };
    case SET_SLIDER_VALUE:
      return {
        ...state,
        sliderValue: action.payload
      };
    default:
      return {
        ...state,
      };
  }
};

export default recommendationReducer;
