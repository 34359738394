import React from "react";
import styled from "styled-components";
import Indicate from "../../assets/images/indicate.svg";
import Bulb from "../../assets/images/bulb.svg";
import Camera from "../../assets/images/camera.svg";
import Stand from "../../assets/images/stand.svg";
import Thumb from "../../assets/images/thumb.svg";


import PhaseIndicator from "./PhaseIndicator";

const Container: any = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  z-index: 1;
  margin-top: 30px;
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
  }
`;

const Indicator: any = styled.div<{ $index: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props: any) => props.$index > 4 ? '25%' : '100%'};
`;

const HR: any = styled.hr.withConfig({
  shouldForwardProp: (prop) => prop !== 'index' && prop !== 'active'
})<{ $index: number, active?: boolean }>`
  width: 100%;
  display: ${(props: any) => props.$index > PhaseElement.length -1 ? 'none' : 'block'};
  background-color: #003777;
  border: 1px solid ${(props: any) => props.active ? '#003777' : '#A4CEFF'};
`;

const PhaseElement = [Indicate, Bulb, Camera, Stand, Thumb,];

const activeColors = {
  active: '#003777',
  notProceed: '#A4CEFF',
  proceed: '#05A43B',
  getColor: function (index: number, iconIndex: number) {
    if(index === iconIndex) {
      return this.active;
    } else if (index < iconIndex) {
      return this.proceed;
    } else {
      return this.notProceed;
    }
  }
}

const PhaseMenu = ({ phaseNumber }: any) => {
  return (
    <Container>
      {PhaseElement.map((data: any, index: number) => {
        const phase = index + 1;
        const isLastPhase = phase > PhaseElement.length - 1;
        return (<Indicator $index={phase} key={index}>
          <PhaseIndicator bgcolor={activeColors.getColor(phase, phaseNumber)} src={data} lastphase={isLastPhase ? true : undefined} />
          <HR $index={phase} active={phaseNumber > phase ? true : undefined} />
        </Indicator>
        );
      })}
    </Container>
  );
};

export default PhaseMenu;
