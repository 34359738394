import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import Button from "@material-ui/core/Button";
import { getMeasurement } from "../../../api/recommendation-api";
import { reuseMeasurements } from "../../../actions/recommendationAction";

const Container = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5%;
  @media (max-width: 786px) {
    min-height: 470px;
  }
  .MuiButton-containedPrimary {
    max-width: 210px;
    width: 100%;
    height: 48px;
    color: #003471;
    margin-top: 40px;
    font-weight: bold;
    border-radius: 20px;
    background: #60dbf0 !important;
  }
`;

const InputContainer = styled.div`
  margin-top: 1em;
  width: 80%;
  margin-left: 10%;
  margin-top: 2em;
  color: #fff;
`;

const Input = styled.input`
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  display: block;
  border: 0;
  outline: 0;
  color: #fff;
  background: transparent;
  border-bottom: 1px solid #60dbf0;
`;

const ErrorContainer = styled.span`
  color: red;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10%;
  button {
    max-width: 100px !important;
  }
`;

const PageTitle = styled.label`
  color: #fff;
  display: flex;
  padding-top: 30px;
  font-size: 26px;
  justify-content: center;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const SubTitle = styled.label`
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  color: #60dbf0;
`;

const ReuseMeasurement = () => {
  const [measurementId, setMeasurementId] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Container>
      <PageTitle>{t("My measurement ID")}</PageTitle>

      <InputContainer>
        <SubTitle>{t("measurement_id")}</SubTitle>
        <Input
          type="text"
          value={measurementId}
          onChange={(e) => setMeasurementId(e.target.value)}
        />
        <ErrorContainer>{error}</ErrorContainer>
      </InputContainer>

      <ButtonContainer>
        <Button
          variant="contained"
          color="primary"
          disabled={!measurementId}
          type="submit"
          onClick={() => {
            getMeasurement(measurementId)
              .then((me) => dispatch(reuseMeasurements(me.measurement)))
              .catch((err) => setError(t("Measurement ID is invalid.")));
          }}
        >
          {t("next")}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ReuseMeasurement;
