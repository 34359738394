import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.body};
    color: ${({ theme }) => theme.colors.color};
    font-family: 'Roboto', sans-serif;
    transition: all 0.50s linear;
    
    .MuiButton-containedPrimary {
        background-color: #003777;
        font-weight: 700;
        @media (max-width: 600px){
            font-size: 13px;
        }
    }
    .MuiInputBase-input {
        color: ${({ theme }) => theme.colors.color};
    }
    .MuiFormHelperText-root {
        color: ${({ theme }) => theme.colors.color};
    }
    .MuiInput-underline:before {
        border-bottom: 1px solid ${({ theme }) => theme.colors.color};
    }
    .MuiNativeSelect-icon {
        color: ${({ theme }) => theme.colors.color};
    }
    .MuiPaper-elevation1 {
        box-shadow: 0 0 0 0;
    }
    .MuiTableContainer-root {
        scrollbar-width: 0px;
        scrollbar-color: #c4c4c4 #f4f4f4;
      }
      
      .MuiTableContainer-root::-webkit-scrollbar {
        display: block;
        width: 0px;
      }
      
      .MuiTableContainer-root::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
        border-right: none;
        border-left: none;
        border-radius: 20px;
      }
      
      .MuiTableContainer-root::-webkit-scrollbar-track-piece:end {
        background: #f5f5f5;
        margin-bottom: 0px;
      }
      
      .MuiTableContainer-root::-webkit-scrollbar-track-piece:start {
        background: #f5f5f5;
        margin-top: 70px;
      }
    .MuiPaper-root {
        background-color: ${({ theme }) => theme.colors.card};
     }
     .MuiInputLabel-outlined {
        color: ${({ theme }) => theme.colors.borderColor}
     }
    .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.borderColor}
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.color}
    }
    .MuiButton-contained.Mui-disabled {
        background-color: ${({ theme }) => theme.colors.disableButtonBg};
        color: ${({ theme }) => theme.colors.disableButtonColor};
    }
    .heading {
        color: ${({ theme }) => theme.colors.text};
        font-weight: 700;
    }
    
    .bb-1 {
        border-bottom: 1px solid ${({ theme }) => theme.colors.color}
    }
  }

  a {
    color: ${({ theme }) => theme.colors.link.text};
    cursor: pointer;
  }
`;