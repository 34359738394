import React from "react";
import styled from "styled-components";
import {
  PHASE_FRONT_VIEW_CAMERA,
  PHASE_FRONT_VIEW_INSTRUCTIONS,
  PHASE_FRONT_VIEW_REVIEW,
  PHASE_MEASUREMENTS,
  PHASE_MEASURING,
  PHASE_MISSING_API_KEY,
  PHASE_PROCESS_FRONT_PICTURE,
  PHASE_PROCESS_SIDE_PICTURE,
  PHASE_SIDE_VIEW_CAMERA,
  PHASE_SIDE_VIEW_INSTRUCTIONS,
  PHASE_SIDE_VIEW_REVIEW,
} from "../../actions/recommendationAction";
import PhaseMenu from "./PhaseMenu";

const Container: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Header = (props: any) => {
  const { phase } = props;
  let phaseNro: number | null = 1;
  switch (phase) {
    case PHASE_FRONT_VIEW_INSTRUCTIONS: {
      phaseNro = 2;
      break;
    }
    case PHASE_FRONT_VIEW_REVIEW: {
      phaseNro = 4;
      break;
    }
    case PHASE_SIDE_VIEW_INSTRUCTIONS: {
      phaseNro = 4;
      break;
    }
    case PHASE_SIDE_VIEW_REVIEW: {
      phaseNro = 5;
      break;
    }
    case PHASE_MEASUREMENTS: {
      phaseNro = 6;
      break;
    }
    case PHASE_MISSING_API_KEY:
    case PHASE_PROCESS_FRONT_PICTURE:
    case PHASE_PROCESS_SIDE_PICTURE:
    case PHASE_MEASURING:
    case PHASE_SIDE_VIEW_CAMERA:
    case PHASE_FRONT_VIEW_CAMERA: {
      phaseNro = null;
      break;
    }
  }

  return (
    <Container>
      {phaseNro && <PhaseMenu phaseNumber={phaseNro} />}
    </Container>
  );
};

export default Header;
