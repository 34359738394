import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux"

import './index.css'
import App from './App'
import { store } from './store'
import './views/translations/i18n'
import reportWebVitals from './reportWebVitals'

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error("Root element with id 'root' not found");
}

createRoot(rootElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

reportWebVitals();
