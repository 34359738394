import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'

const TextFieldDiv = styled(TextField)`
  margin-top: 10px;
`

const ReduxPrimaryInput = (props: any) => {
    const { input, label, width, errResp, type, InputProps, meta: { active, visited, touched, error }, ...custom } = props
    return (
        <TextFieldDiv
            style={{ width}}
            label={(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : label}
            error={(((!active && (visited || touched)) && error) || !!errResp) ? (error || errResp) : null}
            fullWidth
            type={type}
            InputProps={InputProps}
            {...input}
            {...custom}
        />
    )
}

export default ReduxPrimaryInput
