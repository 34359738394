import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import DesignGIF from "../../../assets/images/design.gif";

const Container = styled.div`
  display: flex;
  min-height: 70vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${({ theme}) => theme.colors.text};
`;

const DesignGifImage = styled.img`
  text-align: center;
  width: 64px;
  margin: 0 auto;
`;

const MeasuringText = styled.div`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const Measuring = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <DesignGifImage src={DesignGIF} alt="Design_GIF" />
      <MeasuringText>{t("pleaseWaitMeasuring")}</MeasuringText>
    </Container>
  );
};

export default Measuring;
