/**
 * @license
 * Copyright 2022 Google LLC.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

export class AdapterInfo {
  private vendor: string;
  private architecture: string;
  public intelGPUGeneration: number;

  constructor(adapterInfo: GPUAdapterInfo) {
    if (adapterInfo) {
      this.vendor = adapterInfo.vendor;
      this.architecture = adapterInfo.architecture;
      this.intelGPUGeneration = this.getIntelGPUGeneration();
    }
  }

  private getIntelGPUGeneration() {
    if (this.isIntel()) {
      if (this.architecture.startsWith('gen')) {
        return Number(this.architecture.match(/\d+/));
      } else if (this.architecture.startsWith('xe')) {
        return 12;
      }
    }
    return 0;
  }

  isIntel(): boolean {
    return this.vendor === 'intel';
  }
}
