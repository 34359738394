import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import Card from '../../common/Card';
import ShopSvg from '../../assets/images/shop-icon.svg';
import { ThemeContext } from 'styled-components'
import { useSelector } from 'react-redux';

const Container = styled.div`
    margin-top: 30px; 
    width: 100%;
    overflow-y: scroll;
`;

const SubContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.color}
`;

const Image: any = styled.img`
    height: 25px;
    cursor: pointer;
    width: 25px;
    margin-left: 10px;
    @media (max-width: 600px) {
        margin-left: 0px;
    }
`

const Label = styled.p`
    margin: 0px;
    font-size: 16px;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.color};
    cursor: pointer;
`;

function Shops() {
    const { t } = useTranslation();
    const themeContext = useContext(ThemeContext);
    const shopUrl = useSelector((state: any) => state.userInfo.shopUrl);

    const handleOnclick = (url: string) => {
        console.log('handleOnclick', url)
        if (url.indexOf("https://") === -1) {
            url = "https://" + url;
        }
        window.open(url);
    }

    return <Card
        heading={t(`shops_with_sizey`)}
        logo={ShopSvg}
    >
        <Container>
            {shopUrl.length > 0 && themeContext && shopUrl.map((item: any) =>
                <SubContainer>
                    <Image width={15} height={15} src={themeContext?.['globe']} alt='logo-image' />
                    <Label onClick={() => handleOnclick(item.shopUrl)}>
                        {item.shopUrl}
                    </Label>
                </SubContainer>
            )}
        </Container>
    </Card>
}

export default Shops;