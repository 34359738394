import React, { useContext, useEffect } from 'react'
import 'firebase/auth'
import { connect, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { formValueSelector, reduxForm, reset } from 'redux-form'
import EditProfile from './EditProfile'
import { FirebaseContext } from '../../providers/FirebaseProviders'
import { getUserMeasurements } from '../../actions/UserAction'
import ShowMeasurements from './MeasurementList'
import Shops from './Shops'
import { MY_MEASUREMENT, SIZEY_PROFILE, SIZEY_SHOP } from '../../actions/recommendationAction'
import { useLocation } from 'react-router-dom'


const FORM_NAME = 'edit_profile'

const Container: any = styled.div<{ $gender?: string }>`  
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    justify-content: center;

`
const SubContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`

const ProfileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    @media(max-width: 768px) {
        width: 100%;
        padding-top: 0px;
        flex-direction: column;
        align-items: center;
    }
    @media(max-width: 600px) {
        width: 100%;
        padding-top: 0px;
        flex-direction: column;
        align-items: center;
    }
    
`
const ProfileBgImage: any = styled.div<{ $gender?: string }>`
    width: 25%;
    background: url(${(props: any) => (props.$gender && props.$gender === 'female') ? ({ theme }) => theme.female_silhouette : ({ theme }) => theme.male_silhouette});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media(max-width: 600px) {
        display: none;
    }
`

const Home = (props: any) => {
    const { handleSubmit, isGender, isHeight, isWeight, displayNameValue, loginUser, invalid, fitPreference, useTapeMeasurement } = props

    const dispatch = useDispatch()
    const authContext = useContext(FirebaseContext)
    const homePhase = useSelector((state: any) => state.recommendationReducer.homePhase)
    const location = useLocation();

    useEffect(() => {
        // window.addEventListener("message", saveMeasurement);
        getMesaurements();
        // eslint-disable-next-line
    }, [location.search])

    const getMesaurements = async () => {
        const token = await authContext?.user?.getIdToken(true);
        await dispatch(getUserMeasurements(token));
    }

    // const saveMeasurement = async (e: any) => {
    //     const sizeyEvent = e.data;
    //     if (sizeyEvent.event === 'sizey-measurements') {
    //         const token = await authContext?.user?.getIdToken(true);
    //         if (sizeyEvent.measurements?.measurementId) {
    //             if (!showServices.some((s: any) => s.measurement_id === sizeyEvent.measurements?.measurementId)) {
    //                 await dispatch(userMeasurement({ measurement_id: sizeyEvent.measurements?.measurementId }, token));
    //             }
    //         }
    //     }
    // }

    return (
        <React.Fragment>
            {Object.keys(loginUser).length > 0 && <Container $gender={loginUser.gender}>
                <SubContainer>
                    <ProfileWrapper>
                        {homePhase === SIZEY_PROFILE && <EditProfile
                            handleSubmit={handleSubmit}
                            isGender={isGender}
                            isWeight={isWeight}
                            isHeight={isHeight}
                            reset={reset}
                            invalid={invalid}
                            FORM_NAME={FORM_NAME}
                            displayNameValue={displayNameValue}
                            fitPreference={fitPreference}
                            loginUser={loginUser}
                            useTapeMeasurement={useTapeMeasurement}
                        />}
                        {homePhase === MY_MEASUREMENT && <ShowMeasurements loginUser={loginUser} />}
                        {homePhase === SIZEY_SHOP && <Shops />}
                    </ProfileWrapper>
                    <ProfileBgImage $gender={loginUser.gender} />
                </SubContainer>
            </Container>}
        </React.Fragment>
    )
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state: any) => {
    return {
        loginUser: state.userInfo.user,
        isGender: selector(state, 'gender'),
        isHeight: selector(state, 'height'),
        isWeight: selector(state, 'weight'),
        displayNameValue: selector(state, 'displayName'),
        fitPreference: selector(state, 'fitPreference'),
        useTapeMeasurement: selector(state, 'useTapeMeasurement'),
        initialValues: {
            gender: state.userInfo.user.gender,
            height: state.userInfo.user.height,
            weight: state.userInfo.user.weight,
            displayName: state.userInfo.user.displayName,
            fitPreference: state.userInfo.user.fitPreference,
            useTapeMeasurement: state.userInfo.user.useTapeMeasurement
        }
    }
}

const HomeView = reduxForm({ form: FORM_NAME, enableReinitialize: true })(Home)

export default connect(mapStateToProps, null)(HomeView)
