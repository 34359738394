import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-top: 3%;
  .recommendation {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const StyledSizeRecommendation = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 100px;
  color: ${({ theme }) => theme.colors.text};
`;

const FoundForCloth = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
  margin: 30px 0px;
  text-align: center;
`;

const SizeRecommendation = (props: any) => {
  const { t } = useTranslation();

  const { cloth, measureOnly } = props;

  const recommendations = useSelector((state: any) =>
    state.recommendationReducer.recommendations
  );
  const isValidModelPath = useSelector((state: any) =>
    state.recommendationReducer.isValidModelPath
  );
  const productName = recommendations?.length ? recommendations[0]?.product?.name : null;
  const recommendation = recommendations && recommendations.length > 0 ? recommendations[0] : null;

  // if (recommendations.success === false) return <FoundForCloth>{t(`expireError`)}</FoundForCloth>

  if (!measureOnly && !recommendation) {
    return (
      <div>
        {isValidModelPath && <FoundForCloth>{t('measurementReady')}</FoundForCloth>}
        {/* <FoundForCloth>
          {t("noRecommendationFoundForCloth", { cloth: (cloth || productName) })}
        </FoundForCloth> */}
      </div>
    );
  }

  return (
    <Container>
      {measureOnly ? (
        <span className="recommendation">{t("yourBodyMeasurements")}</span>
      ) : (
        <span className="recommendation">{t("sizeyRecommendationForCloth", { cloth: (cloth || productName) })}</span>
      )}
      {!measureOnly && (
        <StyledSizeRecommendation>
          {recommendation.sizes?.map((s: any) => s.size).join(", ")}
        </StyledSizeRecommendation>
      )}
      {measureOnly && <div style={{ height: "100px" }}></div>}
    </Container>
  );
};

export default SizeRecommendation;
