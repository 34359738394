import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    height: 480px;
    min-width: 300px;
    border-radius: 20px;
    margin: 30px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.card};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    @media(max-width: 600px) {
        width: 75%;
        min-width: 0px;
        margin: 35px 15px;
        padding: 15px;
    }
`

const SubContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 270px;
    min-width: 340px;
    @media(max-width: 600px) {
        min-width: 0px;
    }
`;

const HeadingSection = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0px;
    .MuiTypography-h6 {
        font-size: 17px;
    }
    background: linear-gradient(90deg, #F293AB 0%, #F293AB 0%, #88A4F5 100%, #88A4F5 100%);
    border-radius: 18px;
    height: 45px;
    min-height: 45px;
    width: 100%;
    display: flex;
    font-size: 16px;
    font-weight: 700;
`;

const ButtonSection = styled.div`
    margin-top: 10px;
`;

const Heading = styled.div`
    margin-left: 10px;
`;

const Logo = styled.img`
    width: 65px;
    height: 65px;
    margin-left: -2px;
`;


const Card = (props: any) => {
    const { heading, children, measurementIcons, button, sizeRecommendation, logo } = props;
    return (
        <Container>
            <HeadingSection>
                {!!measurementIcons && measurementIcons}
                {logo && <Logo src={logo}/>}
                <Heading>{heading}</Heading>
            </HeadingSection>
            {!!sizeRecommendation && sizeRecommendation}
            <SubContainer>{children}</SubContainer>
            <ButtonSection>
                {button}
            </ButtonSection>
        </Container>
    )
}

export default Card
