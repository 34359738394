import React, { useEffect, useState, useContext, Fragment } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as moveNet from "@tensorflow-models/pose-detection";
import * as tf from '@tensorflow/tfjs';

import Camera from "./Camera";
import Returning from "./Returning";
import MissingApiKey from "./MissingApiKey";
import Measuring from "./Measuring";
import ViewReview from "./ViewReview";
import ViewInstructions from "./ViewInstructions";
import Header from "../Header";
import ProcessSilhouette from "./ProcessSilhouette";
import BasicInformation from "./BasicInformation";
import ReuseMeasurement from "./ReuseMeasurement";
import Measurements from "./Measurements";
import {
    startMenu,
    setShowPhase,
    reuseMeasurementId,
    PHASE_MISSING_API_KEY,
    PHASE_START,
    PHASE_RETURNING,
    PHASE_BASIC,
    PHASE_REUSE_MEASUREMENT,
    PHASE_FRONT_VIEW_INSTRUCTIONS,
    PHASE_FRONT_VIEW_CAMERA,
    PHASE_SIDE_VIEW_CAMERA,
    PHASE_PROCESS_FRONT_PICTURE,
    PHASE_PROCESS_SIDE_PICTURE,
    PHASE_MEASURING,
    PHASE_FRONT_VIEW_REVIEW,
    PHASE_SIDE_VIEW_INSTRUCTIONS,
    PHASE_MEASUREMENTS,
    PHASE_SIDE_VIEW_REVIEW,
    captureImage,
} from "../../../actions/recommendationAction";
import UploadImage from "../UploadImage";
import { FirebaseContext } from "../../../providers/FirebaseProviders";

const Container: any = styled.div.withConfig({
    shouldForwardProp: (prop) => !['iscamera', 'recommendationpage'].includes(prop)
  })<{ iscamera?: boolean, recommendationpage?: boolean }>`
  width: ${(props: any) => props.iscamera ? "100%" : "90%"};
  height: 100%;
  margin: 0 auto;
  @media (max-width: 600px) {
    height: 100%;
  }
`;

const CameraWrapper: any = styled.div.withConfig({
    shouldForwardProp: (prop) => !['loading'].includes(prop)
  })<{ loading?: boolean }>`
  font-size: 12px;
  width: 100%;
  height: ${(props: any) => props.loading && "83vh"};
  display: ${(props: any) => props.loading ? "flex" : "block"};
  justify-content: center;
  align-items: center;
`;

const LoadingScreen = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
`;

const RecommendationHeader = styled.div``;

const InnerBody = styled.div`
  font-size: 35px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const PageTitle = styled.label`
  font-size: 25px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

function Recommend(props: any) {
    const { t } = useTranslation();
    const [showError, setShowError] = useState('')
    const [moveNetModel, setMoveNetModel] = useState(null);
    const measurementId = useSelector((state: any) => state.userInfo);
    const navigate = useNavigate();
    const { frontPicture, sidePicture, phase, isMeasurement } = useSelector(
        (state: any) => state.recommendationReducer
    );
    tf.setBackend('webgl');
    const location: any = useLocation();
    const dispatch = useDispatch();

    const authContext = useContext(FirebaseContext);
    const query = new URLSearchParams(location.search);
    const paramsMeasurementId = query.get("measurementId");

    useEffect(() => {
        if (window.opener) {
            window.opener.postMessage({ event: 'sizey-started' }, '*');
        }
        window.postMessage({ event: 'sizey-started' }, '*');
        initializeTensorFlowAndModel();
    }, [])

    const initializeTensorFlowAndModel = async () => {
        await tf.ready();
        const network: any = await moveNet.createDetector(
            moveNet.SupportedModels.MoveNet,
            { modelType: moveNet.movenet.modelType.SINGLEPOSE_LIGHTNING }
        );
        setMoveNetModel(network);
    };

    const handleRoutesByParams = async () => {
        const apiKey = query.get("apikey");
        let id = null;
        if (measurementId?.measurements.length > 0) {
            const tapeMeasurement = measurementId.measurements.filter((m: any) => m.tape);
            const digitalMeasurement = measurementId.measurements.filter((m: any) => !m.tape);
            if (measurementId.user.useTapeMeasurement) {
                if (tapeMeasurement.length > 0) {
                    id = tapeMeasurement[0].measurement_id;
                } else {
                    id = digitalMeasurement[0].measurement_id;
                }
            } else {
                if (digitalMeasurement.length > 0) {
                    id = digitalMeasurement[0].measurement_id;
                } else {
                    id = tapeMeasurement[0].measurement_id;
                }
            }
        } else {
            id = null;
        }
        const firstMeasurementId = id;

        if (!apiKey) {
            dispatch(setShowPhase(PHASE_MISSING_API_KEY));
        } else {
            if (paramsMeasurementId === "null" || paramsMeasurementId === 'undefined') {
                const newSearch: any = new URLSearchParams(location.search);
                let query1 = "?";
                for (const param of newSearch) {
                    if (param[0] !== "measurementId") {
                        query1 = query1 + "&" + param[0] + "=" + param[1];
                    }
                }
                navigate(
                    `${query1 + "&measurementId=" + firstMeasurementId}`
                );
                dispatch(reuseMeasurementId(firstMeasurementId));
            } else if (paramsMeasurementId) {
                dispatch(reuseMeasurementId(paramsMeasurementId));
            } else {
                dispatch(startMenu());
            }
        }
    };

    useEffect(() => {
        loadMoveNetModel();
        if (showError) {
            window.alert(showError);
            setShowError("");
        }
        // eslint-disable-next-line
    }, [showError]);

    useEffect(() => {
        handleRoutesByParams();
        // eslint-disable-next-line
    }, [measurementId.measurements?.length]);

    const iscamera =
        phase === PHASE_FRONT_VIEW_CAMERA || phase === PHASE_SIDE_VIEW_CAMERA;

    const loadMoveNetModel = async () => {
        const network: any = await moveNet.createDetector(
            moveNet.SupportedModels.MoveNet,
            { modelType: moveNet.movenet.modelType.SINGLEPOSE_LIGHTNING }
        );
        setMoveNetModel(network);
    };

    useEffect(() => {
        if (frontPicture && sidePicture) {
            dispatch(setShowPhase(PHASE_FRONT_VIEW_REVIEW));
        }
        // eslint-disable-next-line
    }, [frontPicture && sidePicture]);

    const appOnCapture = (image: any, orientation: string) => {
        dispatch(captureImage({ image, orientation, fileExtension: true }));
    };
    return (
        <Container
            iscamera={iscamera || ((paramsMeasurementId === 'null' || paramsMeasurementId === 'undefined') && !authContext.user && !isMeasurement) ? true : undefined}
            recommendationpage={phase === PHASE_MEASUREMENTS ? true : undefined}
        >
            <Fragment>
                <RecommendationHeader>
                    {!iscamera && <Header phase={phase} />}
                </RecommendationHeader>
                <InnerBody>
                    {phase === PHASE_MISSING_API_KEY && <MissingApiKey />}
                    {phase === PHASE_START && <BasicInformation />}
                    {phase === PHASE_RETURNING && <Returning />}
                    {phase === PHASE_BASIC && <BasicInformation />}
                    {phase === PHASE_REUSE_MEASUREMENT && <ReuseMeasurement />}
                    {phase === PHASE_FRONT_VIEW_INSTRUCTIONS && (
                        <ViewInstructions>{t("viewInstructionsFront")}</ViewInstructions>
                    )}
                    {phase === PHASE_FRONT_VIEW_CAMERA && (
                        <CameraWrapper loading={!moveNetModel}>
                            {moveNetModel === null ? <LoadingScreen>{t("loading_screen")}</LoadingScreen>
                                : <Camera
                                    orientation="front"
                                    network={moveNetModel}
                                    onCapture={(image: any) => appOnCapture(image, "front")}
                                />}
                            <UploadImage orientation="front" setShowError={setShowError} />
                        </CameraWrapper>
                    )}
                    {phase === PHASE_PROCESS_FRONT_PICTURE && <ProcessSilhouette />}
                    {phase === PHASE_PROCESS_SIDE_PICTURE && <ProcessSilhouette />}
                    {phase === PHASE_MEASURING && <Measuring />}
                    {phase === PHASE_FRONT_VIEW_REVIEW && (
                        <ViewReview
                            frontPicture={frontPicture}
                            sidePicture={sidePicture}
                        ></ViewReview>
                    )}
                    {phase === PHASE_SIDE_VIEW_INSTRUCTIONS && (
                        <ViewInstructions>
                            <PageTitle>{t("viewInstructionsSide")}</PageTitle>
                        </ViewInstructions>
                    )}
                    {phase === PHASE_SIDE_VIEW_CAMERA && (
                        <CameraWrapper loading={!moveNetModel}>
                            {moveNetModel === null ? <LoadingScreen>{t("loading_screen")}</LoadingScreen> : <Camera
                                orientation="side"
                                network={moveNetModel}
                                onCapture={(image: any) => appOnCapture(image, "side")}
                            />}
                            <UploadImage orientation="side" setShowError={setShowError} />
                        </CameraWrapper>
                    )}
                    {phase === PHASE_SIDE_VIEW_REVIEW && (
                        <ViewReview
                            frontPicture={frontPicture}
                            sidePicture={sidePicture}
                        ></ViewReview>
                    )}
                    {phase === PHASE_MEASUREMENTS && <Measurements isMeasurement={isMeasurement} />}
                    {phase === PHASE_SIDE_VIEW_REVIEW && <ViewReview />}
                </InnerBody>
            </Fragment>
        </Container>
    );
}

export default Recommend;
