const API_KEY = "apikey";
const url = new URL(window.location.href);
const apiKey: string | null = url.searchParams.get(API_KEY);

export const AuthUserApi = async (token: any) => {
    return fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}me`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
    }).then((async res => {
        const body = await res.json()
        return body
    })).catch(err => { return err })
}


export const GetShopsUrlApi = async (token: any) => {
    return fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}companies/shop-url`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
    }).then((async res => {
        const body = await res.json()
        return body
    })).catch(err => { return err })
}


export const UserMeasurement = async (measurement: any, token: string | undefined) => {
    return fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}me/measurements`, {
        method: 'POST',
        headers: {
            Accept: '*/*',
            'Content-type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            measurement
        }),
    }).then((async res => {
        return await res.json()
    })).catch(err => { return err })
}

export const GetUserMeasurements = async (token: any) => {
    return fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}me/measurements`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
    }).then((async res => {
        return await res.json()
    })).catch(err => { return err })
}

export const GetAvatar = async (token: any, measurementId: string) => {
    return fetch(`${process.env.REACT_APP_VROOM_API_URL}my-avatar?measurementId=${measurementId}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-Sizey-Key": `${apiKey}`
        },
    }).then((async res => {
        return await res.json()
    })).catch(err => {
        return err
    })
}


export const GetPoses = async (token: any, avatarId: string) => {
    return fetch(`${process.env.REACT_APP_VROOM_API_URL}avatars/${avatarId}/poses`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "X-Sizey-Key": `${apiKey}`,
        },
    }).then((async res => {
        return await res.text()
    })).catch(err => { return err })
}