import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

import SizeRecommendation from "../SizeRecommendation";
import MeasurementResults from "../MeasurementResults";
import {
  PHASE_RETURNING,
  setClearBothPictures,
  setMeasurementUsingProductId,
  setShowPhase,
  setSizeChart,
} from "../../../actions/recommendationAction";
import { FirebaseContext } from "../../../providers/FirebaseProviders";
// import { getUrlQuery } from "../../../actions/UserAction";

const Container = styled.div`
  width: 100%;
  .MuiButton-containedPrimary {
    max-width: 210px;
    height: 48px;
    width: 100%;
    font-weight: bold;
    border-radius: 20px;
  }
`;

const FailedWrapper = styled.div`
  font-size: 25px;
  font-weight: bold;
  position: relative;
  top: 50%;
  color: ${({ theme }) => theme.colors.text};
  min-height: 70vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    line-height: 40px;
  }
  .MuiButton-containedPrimary {
    max-width: 120px;
    height: 48px;
    width: 100%;
    margin-left: 20px;
    font-weight: bold;
    border-radius: 20px;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Measurements = ({ isMeasurement }: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useContext(FirebaseContext);
  const navigate = useNavigate();

  const targetWindow = (message: any, hosts: string) => {
    if (window.opener) {
      window.opener.postMessage(message, hosts);
    }
    window.postMessage(message, hosts);
  };

  const { measurements, recommendations, measurementFailed, measurementFailedMsg } = useSelector(
    (state: any) => state.recommendationReducer
  );

  const postMessage = async () => {
    if (!measurementFailed) {
      await eventPostMessage(targetWindow);
    }
  }

  useEffect(() => {
    postMessage();

    // eslint-disable-next-line
  }, []);

  const recommendation =
    recommendations && recommendations.length > 0 ? recommendations[0] : null;

  const measurementsVisible = useState(true);

  const eventPostMessage = async (windowPostMessage: any) => {
    await fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}apikey`, {
      headers: new Headers({
        "Content-Type": "application/json",
        "X-Sizey-Key": apiKey,
      }),
    })
      .then((doc) => doc.json())
      .then((doc) => {
        windowPostMessage(
          { event: "sizey-measurements", measurements },
          doc.hosts
        );
        if (recommendation) {
          windowPostMessage(
            { event: "sizey-recommendation", recommendation: recommendation },
            doc.hosts
          );
          windowPostMessage(
            {
              event: "sizey-recommendations",
              recommendations: recommendations,
            },
            doc.hosts
          );
        }
      });
  };


  const url: any = new URL(window.location.href);
  const measureOnly = url.searchParams.get("measureOnly");
  const cloth = url.searchParams.get("itemName");
  const apiKey = url.searchParams.get("apikey");
  const storeName = url.searchParams.get("storeName");

  const returnToHome = async () => {
    const queryParams = new URLSearchParams(url.search);
    const pathParam = queryParams.get("path");
    dispatch(setClearBothPictures());
    dispatch(setShowPhase(PHASE_RETURNING));
    if (window.opener === null && isMeasurement) {
      dispatch(setMeasurementUsingProductId(false));
      navigate(`${url.pathname + url.search}`);
    } else if (window.opener === null) {
      // const url = getUrlQuery();
      if (pathParam && pathParam === "/recommendation") {
        const modifiedParams = new URLSearchParams(queryParams);
        if (modifiedParams.has('measurementId')) {
          modifiedParams.set('measurementId', measurements.measurementId);
        } else {
            modifiedParams.append('measurementId', measurements.measurementId);
        }
        if (modifiedParams.has('saved')) {
          modifiedParams.delete('saved');
        }
        modifiedParams.delete('path');
        let recommendationUrl;
        if( user ) {
          recommendationUrl = `${process.env.REACT_APP_UI_RECOMMENDATION}?${modifiedParams.toString()}`;
        } else {
          recommendationUrl = `${process.env.REACT_APP_UI_RECOMMENDATION}?${modifiedParams.toString()}&saved`;
        }
        window.location.href = recommendationUrl;
      } else {
        // Redirect to the home page
        navigate(`/`);
        dispatch(setShowPhase(""));
        dispatch(setSizeChart(undefined));
      }
    } else {
      if (pathParam && pathParam === "/recommendation") {
        const modifiedParams = new URLSearchParams(queryParams);
        if (modifiedParams.has('measurementId')) {
          modifiedParams.set('measurementId', measurements.measurementId);
        } else {
            modifiedParams.append('measurementId', measurements.measurementId);
        }
        if (modifiedParams.has('saved')) {
          modifiedParams.delete('saved');
        }
        
        modifiedParams.delete('path');
        let recommendationUrl;
        if( user ) {
          recommendationUrl = `${process.env.REACT_APP_UI_RECOMMENDATION}?${modifiedParams.toString()}`;
        } else {
          recommendationUrl = `${process.env.REACT_APP_UI_RECOMMENDATION}?${modifiedParams.toString()}&saved`;
        }
        window.location.href = recommendationUrl;
      } else {
        window.close();
      }
    }
  };

  return (
    <Container>
      {measurementsVisible && measurements && !measurementFailed && (
        <div>
          <SizeRecommendation cloth={cloth} measureOnly={measureOnly} />
          <MeasurementResults measurement={measurements} />
        </div>
      )}

      {measurementFailed && (
        <FailedWrapper>
          {t(measurementFailedMsg)}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={returnToHome}
          >
            {t("Try Again")}
          </Button>
        </FailedWrapper>
      )}

      {!measurementFailed && (
        <ButtonSection>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={returnToHome}
          >
            {t("returnToStore", { storeName })}
          </Button>
        </ButtonSection>
      )}
    </Container>
  );
};

export default Measurements;
