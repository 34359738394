import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Recommend from './Recommend';

import { setMeasurements } from "../../../actions/recommendationAction";
import {
  startMenu,
  setShowPhase,
  reuseMeasurementId,
  PHASE_MISSING_API_KEY,
} from "../../../actions/recommendationAction";
import { getMeasurement } from "../../../api/recommendation-api";
import { FirebaseContext } from "../../../providers/FirebaseProviders";
import { userMeasurement } from "../../../actions/UserAction";

const Container: any = styled.div`
  width: "100%";
  height: 100%;
  margin: 0 auto;
`;

// const spin = keyframes`
//   0% { transform: rotate(0deg); }
//   100% { transform: rotate(360deg); }
// `;

// const LoadingScreen = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 20px;
//   font-weight: bold;
//   color: ${({ theme }) => theme.colors.text};
//   height: 100vh;
  
//   &::after {
//     content: '';
//     width: 40px;
//     height: 40px;
//     border: 4px solid ${({ theme }) => theme.colors.primary}; /* Choose your desired color */
//     border-top: 4px solid transparent;
//     border-radius: 50%;
//     animation: ${spin} 1s linear infinite; /* Apply the spin animation */
//   }
// `;

function Recommendation(props: any) {
  const measurementId = useSelector((state: any) => state.userInfo);
  const { recommendations } = useSelector(
    (state: any) => state.recommendationReducer
  );
  const showServices = useSelector((state: any) => state.userInfo.measurements)
  const location: any = useLocation();
  const dispatch = useDispatch();
  const authContext = useContext(FirebaseContext)
  const query = new URLSearchParams(location.search);
  const paramsMeasurementId = query.get("measurementId");
  const url = new URL(window.location.href);
  const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
  const productId: any = url.searchParams.get('productId');
  const navigate = useNavigate();

  const [urlMeasurementId, setUrlMeasurementId] = useState('');

  const handleRoutesByParams = async () => {
    const apiKey = query.get("apikey");
    let id = null;

    if (paramsMeasurementId !== "null" && paramsMeasurementId !== 'undefined') {
      id = paramsMeasurementId;
    }
    else if (measurementId?.measurements.length > 0) {
      const tapeMeasurement = measurementId.measurements.filter((m: any) => m.tape);
      const digitalMeasurement = measurementId.measurements.filter((m: any) => !m.tape);
      if (measurementId.user.useTapeMeasurement) {
        if (tapeMeasurement.length > 0) {
          id = tapeMeasurement[0].measurement_id;
        } else {
          id = digitalMeasurement[0].measurement_id;
        }
      } else {
        if (digitalMeasurement.length > 0) {
          id = digitalMeasurement[0].measurement_id;
        } else {
          id = tapeMeasurement[0].measurement_id;
        }
      }
    } else {
      id = null;
    }
    setUrlMeasurementId(id)
    const firstMeasurementId = id;

    if (!apiKey) {
      dispatch(setShowPhase(PHASE_MISSING_API_KEY));
    } else {
      if (paramsMeasurementId === "null" || paramsMeasurementId === 'undefined') {
        const newSearch: any = new URLSearchParams(location.search);
        let query1 = "?";
        for (const param of newSearch) {
          if (param[0] !== "measurementId") {
            query1 = query1 + "&" + param[0] + "=" + param[1];
          }
        }
        navigate(
          `${query1 + "&measurementId=" + firstMeasurementId}`
        );
        if (!UPC && !productId) {
          dispatch(reuseMeasurementId(firstMeasurementId));
        }
      } else if (paramsMeasurementId) {
        if (!UPC && !productId) {
          dispatch(reuseMeasurementId(paramsMeasurementId));
        }
      } else {
        dispatch(startMenu());
      }
    }
  };

  useEffect(() => {
    handleRoutesByParams();
    // eslint-disable-next-line
  }, [measurementId.measurements?.length]);

  useEffect(() => {
    if (urlMeasurementId) {
      getMeasurement(urlMeasurementId)
        .then((me) => dispatch(setMeasurements(me.measurement)))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [urlMeasurementId])

  useEffect(() => {
    window.addEventListener("message", saveMeasurement);
    //window.location.replace(`${process.env.REACT_APP_UI_RECOMMENDATION}${window.location.search}`);
    // eslint-disable-next-line
  }, [])
  
  const saveMeasurement = async (e: any) => {
    const sizeyEvent = e.data;
    if (sizeyEvent.event === 'sizey-measurements') {
      const token = await authContext?.user?.getIdToken(true);
      if (sizeyEvent.measurements?.measurementId) {
        if (!showServices.some((s: any) => s.measurement_id === sizeyEvent.measurements?.measurementId)) {
          await dispatch(userMeasurement({ measurement_id: sizeyEvent.measurements?.measurementId }, token));
        }
      }
    }
  }

  return (
    <Container>
      {(!recommendations || (typeof recommendations === 'boolean' && !UPC && !productId)) && <Recommend />}
    </Container>
  );
}

export default Recommendation;
