import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  color: rgb(255, 255, 255);
  min-height: 76vh;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px 20px;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const Returning = () => {
  const { t } = useTranslation();

  return <Container>{t("returningToStorePleaseWait")}</Container>;
};

export default Returning;
